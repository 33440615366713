import { appContext } from '../../../../AppContext';
import { IOption } from '../../../../Components/Layout/BuildingSelector/BuildingSelector';
import { IFloor } from '../../../../Providers.Api/Models';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import Helper from '../../../../Common/Helper';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import SvgIcon from '@mui/material/SvgIcon';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';

class ScheduleFilter extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingOptions: [],
            floorOptions: [],
            spaceTypeOptions: [],
            workTypeOptions: [],
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.populateBuildings();
        this.populateFloors(this.props.buildingId);
        this.populateWorkTypes(this.props.buildingId);
        this.populateSpaceTypes(this.props.buildingId);
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevProps.buildingId !== this.props.buildingId)
        {
            this.populateFloors(this.props.buildingId);
            this.populateWorkTypes(this.props.buildingId);
            this.populateSpaceTypes(this.props.buildingId);
        }
    }

    private populateBuildings(): void
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const buildingOptions = buildings.map(i => ({ value: i.Node_Id, label: i.Name }) as IOption);
        this.setState({ buildingOptions: buildingOptions });
    }

    private populateFloors(selectedBuildingId: number): void
    {
        const floors = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id.toString() }))
            .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? - 1 : 1)); // sort by name

        options.unshift({ label: this.labels.HubLabelAny, value: '0' });
        this.setStateAsync({ floorOptions: options });
    }

    private populateWorkTypes(selectedBuildingId: number): void
    {
        const workTypes = Helper.getWorkSpaceTypesByNodeId(selectedBuildingId);

        const options = workTypes
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? - 1 : 1)); // sort by name;

        options.unshift({ label: this.labels.HubLabelAny, value: 'Any' });
        this.setState({ workTypeOptions: options });
    }

    private populateSpaceTypes(selectedBuildingId: number): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? - 1 : 1)); // sort by name;

        options.unshift({ label: this.labels.HubLabelAny, value: 'Any' });
        this.setState({ spaceTypeOptions: options });
    }

    public render(): JSX.Element
    {
        return (
            <Grid container spacing={3}>
                <Grid item xs={0.8} alignSelf={'center'}>
                    <Box display='flex' gap={1}>
                        <SvgIcon component={FilterListIcon}></SvgIcon>
                        <Typography
                            sx={{ fontSize: '1rem', fontFamily: 'Source Sans Pro', fontWeight: 'bold' }}
                        >
                            {this.labels.HubLabelFilter}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="buildingSelection"
                            inputLabel={this.labels.HubLabelBuilding}
                            fullWidth={true}
                            options={this.state.buildingOptions}
                            value={this.props.buildingId}
                            onChange={e => { this.props.onBuildingChange(e.target.value) }}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="floorSelection"
                            inputLabel={this.labels.HubLabelFloor}
                            fullWidth={true}
                            options={this.state.floorOptions}
                            value={this.props.floorId}
                            onChange={e => { this.props.onFloorChange(e.target.value) }}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="zoneSelection"
                            inputLabel={this.labels.HubLabelZone}
                            fullWidth={true}
                            options={this.props.zoneOptions}
                            value={this.props.zoneId}
                            onChange={e => { this.props.onZoneChange(e.target.value) }}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="workTypeSelection"
                            inputLabel={this.labels.HubLabelworkType}
                            fullWidth={true}
                            options={this.state.workTypeOptions}
                            value={this.props.workTypeId}
                            onChange={e => { this.props.onWorkTypeChange(e.target.value) }}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={2}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="spaceTypeSelection"
                            inputLabel={this.labels.HubLabelSpaceType}
                            fullWidth={true}
                            options={this.state.spaceTypeOptions}
                            value={this.props.spaceTypeId}
                            onChange={e => { this.props.onSpaceTypeChange(e.target.value) }}
                        />
                    </IbssFormControl>
                </Grid>
            </Grid>
        )
    }
}

export default ScheduleFilter;

export interface IProps
{
    buildingId: number,
    floorId: number,
    zoneId: number,
    zoneOptions: Array<IListOption>,
    workTypeId: string,
    spaceTypeId: string,
    onBuildingChange: (buildingId: number) => void,
    onFloorChange: (floorId: number) => void,
    onZoneChange: (zoneId: number) => void,
    onWorkTypeChange: (workTypeId: string) => void,
    onSpaceTypeChange: (spaceTypeId: string) => void,
}

export interface IState
{
    buildingOptions: Array<IOption>,
    floorOptions: Array<IListOption>,
    spaceTypeOptions: Array<IListOption>,
    workTypeOptions: Array<IListOption>,
}

export interface IListOption
{
    label: string;
    value: string;
}
