import { Component } from "react";
import "../../../../styles/css/table.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import { handleSortTableDataDivWrapProperyArray, getAirStatus } from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import TablePanelInfo from "../../../../Components/Headings/TableHeading/TablePanelInfo"
import { IPartialAppState, appContext } from "../../../../AppContext";
import { FACILITY_ZONE_DETAIL, UPDATE_MAIN_PAGE_TITLE } from "../../../../app/constants";
import { DateTime } from "luxon";
import IbssDataGrid from "../../../../Components/Data/DataGrid/IbssDataGrid";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";

class ListEnvironmentalDailySummariesByZone extends Component {
  private labels = appContext().labels;
  private appState = appContext().state;
  private session = appContext().sessionStorageProvider;
  state = {
    isLoading: false,
    zonesData: [],
    facilityDataSort: { columnName: "", direction: "" },
    zoneList: [],
    currentEnvZoneName: '',
    selectedBuildingId: this.appState.buildingId.toString(),
    selectedZoneIds: []
  };

  public async componentDidMount() {
    const { history, match, dispatch }: any = this.props;
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsZones });
    this.appState.subscribe(this, i => this.appStateChanged(i));

    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });

    this.handleGetZonesData()
  }

  private async appStateChanged(state: IPartialAppState): Promise<void> {
    if (state.buildingId != undefined) {
      this.setState({ selectedBuildingId: state.buildingId?.toString() });
    }
  }

  handleGetZonesData = async () => {
    this.setState({ isLoading: true });
    const { match, location, currentEnvZoneName }: any = this.props;
    const { zoneId } = match.params
    const { search } = location;
    const startDate = search.split('&')[0].split('=')[1];
    const endDate = search.split('&')[1].split('=')[1];
    try {
      const response: any = await apis.getHistoricalZoneData(this.session.getBuildingId(), zoneId, startDate, endDate)
      const responseData = response.data;

      const newResponseData = responseData && responseData[0] && responseData.map((item: any) => {
        let newItem = { ...item }

        newItem.table_Summary_Date_Local = <div onClick={() => this.handleClickZone(item)}>{DateTime.fromISO(item.Summary_Date_Local).toFormat("dd/MM/yyyy HH:mm")}</div>
        newItem.table_IAQ_Status = <div onClick={() => this.handleClickZone(item)}>{getAirStatus(item.IAQ_Status)}</div>
        newItem.table_Env_Temperature = <div onClick={() => this.handleClickZone(item)}>{Math.round(item.Env_Temperature)}{'\u00b0'}C</div>
        newItem.table_Env_Humidity = <div onClick={() => this.handleClickZone(item)}>{Math.round(item.Env_Humidity)}%</div>
        newItem.table_Env_Sound_Level = <div onClick={() => this.handleClickZone(item)}>{Math.round(item.Env_Sound_Level)} dB</div>
        newItem.table_Env_Zone_LastUpdated = <div onClick={() => this.handleClickZone(item)}>{DateTime.fromISO(item.Env_Zone_LastUpdated).toLocaleString(DateTime.DATETIME_SHORT)}</div>
        return newItem
      })

      this.setState({ isLoading: false, currentEnvZoneName: currentEnvZoneName, zoneList: newResponseData ? newResponseData : [] })
      this.setState({ isLoading: false, zonesData: responseData ? responseData : [] })
    } catch (error: any) {
      this.setState({
        isLoading: false,
        spaces: [{ id: 0, name: "" }],
      })
    }
  }

  handleClickZone = async (item: any) => {

    const { dispatch }: any = this.props;
    await dispatch({ type: FACILITY_ZONE_DETAIL, payload: item })

    const { history }: any = this.props;
    history.push(`/sustainability-analytics-zones/${this.session.getBuildingId()}/zones/${item.Env_Zone_Id}`)
  }

  handleFacilityDataSort = (e: any) => {
    this.setState({ facilityDataSort: { columnName: e.column, direction: e.direction }, })
    const newArray = handleSortTableDataDivWrapProperyArray(this.state.zoneList, e.column, e.direction)
    this.setState({ zoneList: newArray })
  }

  private async selectionChanged(selection: GridRowSelectionModel): Promise<void>
  {
      await this.setState({ selectedVisitIds: selection });
  }

  render() {

    const zoneColumns: GridColDef[] = [{
      headerName: this.labels.HubLabelDate,
      field: "Summary_Date_Local",
      minWidth: 140,
      flex: 1,
    },
    {
        headerName: this.labels.HubLabelStatus,
        field: "IAQ_Status",
        minWidth: 140,
        flex: 1,
    },
    {
        headerName: this.labels.HubLabelTemperature,
        field: "Env_Temperature",
        minWidth: 120,
        flex: 1,
    },
    {
        headerName: this.labels.HubLabelHumidity,
        field: "Env_Humidity",
        minWidth: 120,
        flex: 1
    },
    {
      headerName: this.labels.HubLabelNoise,
      field: "Env_Sound_Level",
      minWidth: 120,
      flex: 1
    },
    {
      headerName: this.labels.HubLabelLastUpdate,
      field: "Env_Zone_LastUpdated",
      minWidth: 120,
      flex: 1
    }
  ];

    const rows = this.state.zoneList ? this.state.zoneList : [];

    return (
      <>
        <div className="rightPanel-main-content">
          <div className="table-panel">
            <TablePanelInfo
              tableHeading={this.labels.HubLabelHistoricalData}
              iconSrc="/images/Sidebar_Icons/Light_theme/Zone.svg"
            />
            <div className="position-relative">
              {(this.state.isLoading || this.state.isLoading) && <Spinner />}
              <IbssDataGrid
                 getRowId={(row) => row.Record_Id}
                 checkboxSelection
                 columns={zoneColumns} 
                 rows={rows}
                 onRowSelectionModelChange={e => this.selectionChanged(e)}
                 rowSelectionModel={this.state.selectedZoneIds}
                 initialState={{
                     pagination: { paginationModel: {pageSize: 25} },
                 }}
                 pageSizeOptions={[25,50,100]}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
    currentEnvZoneName: state.currentEnvZoneName,
  };
};

export default connect(mapStateToProps)(ListEnvironmentalDailySummariesByZone);
