import React from 'react';
import
{
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    IconButton,
    Box,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import MediaSelector from '../../../../Components/DialogLaunchers/MediaSelector/MediaSelector';
import { appContext } from '../../../../AppContext';

class FloorMapDialog extends IbssComponent<IProps, IState> 
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps) 
    {
        super(props);
        this.state =
        {
            selectedImage: '',
            imageUrl: '',
        };
    }


    public async handleSave(): Promise<void> 
    {
        this.props.imagePreview(this.state.imageUrl);
        this.props.onClose();
    }


    private async uploadImage(filename: string, file: FormData, processAsMap: boolean): Promise<void>
    {
        try 
        {
            await appContext().webEntryApiClientV1.api.webentry.v1.file.byPath.post({
                path: 'r/Maps/' + filename,
                processAsMapFile: false,
                body: file
            });
            this.props.getImages();
        }
        catch 
        {
        }
    }

    private imageChanged(file: string): void
    {
        this.setState({
            imageUrl: file
        })
        this.props.imagePreview(file);
    }

    private handleCancel(): void
    {
        this.setState({
            imageUrl: '',
            selectedImage: ''
        })
        this.props.imagePreview("");
        this.props.onClose();
    }

    public render(): JSX.Element
    {
        const { open, onClose, mode } = this.props;
        const isViewMode = mode === 'view';

        return (
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{isViewMode ? this.labels.funcViewFloorMap_S : this.labels.funcUploadFloorMap_S}</Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                        {isViewMode ? this.labels.funcViewTheFloorMap_S : this.labels.funcUploadAndConfigure_S}
                    </Typography>
                    <Box sx={{ width: '100%', mb: 2 }}>
                        {!isViewMode && (
                            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                                <IbssTextField
                                    value={this.state.imageUrl}
                                    disabled
                                    variant="outlined"
                                    fullWidth
                                    sx={{ flexGrow: 7, mr: 1 }}
                                />

                                <MediaSelector
                                    noPreview={true}
                                    options={this.props.images.map(i => ({ name: i.name, url: i.path }))}
                                    imageSelected={file =>
                                        {
                                        this.setState({ selectedImage: file.url });
                                        this.imageChanged(file.url)
                                    }}
                                    uploadFile={(filename: string, file: FormData, processAsMap: boolean) => this.uploadImage(filename, file, processAsMap)}
                                    defaultSelectedImage={this.state.imageUrl}
                                />
                            </Box>
                        )}
                        <Box
                            sx={{
                                width: '100%',
                                height: '330px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: isViewMode ? 'default' : 'pointer',
                                bgcolor: 'lightgray',
                            }}
                        >
                            {this.props.selectedImageUrl ? <img src={this.props.selectedImageUrl ? this.props.selectedImageUrl : this.state.selectedImage} alt="selected img" height={300} width={300}/> : <Typography>{this.labels.funcNoResultsFound_S}</Typography>}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    {!isViewMode && (
                        <IbssButton
                            onClick={() => this.handleSave()}
                            color="primary"
                            disabled={!this.state.imageUrl}
                            variant="contained"
                        >
                            {this.labels.HubLabelOk}
                        </IbssButton>
                    )}
                    <IbssButton
                        onClick={() => this.handleCancel()}
                        color="primary"
                        variant="contained"
                    >
                        {isViewMode ? this.labels.HubLabelClose : this.labels.HubButtonCancel}
                    </IbssButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default FloorMapDialog;

export interface IFile
{
    name: string;
    path: string;
    lastModified: string;
    createdAt: string;
    size: string;
}

interface IProps 
{
    open: boolean;
    onClose: () => void;
    mode: 'upload' | 'view';
    images: IFile[];
    imagePreview: (img: string) => void;
    getImages: () => void;
    selectedImageUrl: string;
}

interface IState
{
    selectedImage: string;
    imageUrl: string;
}