import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { appContext } from "../../AppContext";
import * as GetV1Spaces from "./GetV1SpacesEndpoint";
import * as GetV2Spaces from "./GetV2SpacesEndpoint";
import * as GetV1SpaceById from "./GetV1SpaceByIdEndpoint";
import * as SearchV1SpaceById from "./SearchV1SpaceByIdEndpoint";
import * as CheckIn from "./CheckInEndpoint";
import * as CheckOut from "./CheckOutEndpoint";
import { DateHelper } from "../../Common/DateHelper";
import * as SearchSpaces from "./SearchSpacesEndpoint";
import { IDeleteSpaceEndpoint } from "./DeleteSpaceEndpoint";
import { IGetV2AllSpacesEndpoint } from "./GetV2AllSpacesEndpoint";
import { IEnableSpacesEndpoint } from "./EnableSpacesEndpoint";
import { IDisableSpacesEndpoint } from "./DisableSpacesEndpoint";
import { ODataQueryV2, ODataSelect } from "../ODataQuery";
import { PagedResponse } from "../Models";
import { ISearchV2SpaceByIdEndpoint } from "./SearchV2SpaceByIdEndpoint";
import { ISpace, IUpdateV2SpaceByIdEndpoint } from "./UpdateV2SpaceByIdEndpoint";
import { ICreateV2SpaceEndpoint } from "./CreateV2SpaceEndpoint";

export class SpaceRepository implements ISpaceRepository
{
    private getV1SpacesEndpoint: GetV1Spaces.IGetV1SpacesEndpoint;
    private getV2SpacesEndpoint: GetV2Spaces.IGetV2SpacesEndpoint;
    private getV1SpaceByIdEndpoint: GetV1SpaceById.IGetV1SpaceByIdEndpoint;
    private searchV1SpaceByIdEndpoint: SearchV1SpaceById.ISearchV1SpaceByIdEndpoint;
    private checkInEndpoint: CheckIn.ICheckInEndpoint;
    private checkOutEndpoint: CheckOut.ICheckOutEndpoint;
    private searchSpacesEndpoint: SearchSpaces.ISearchSpacesEndpoint;
    private deleteSpaceEndpoint: IDeleteSpaceEndpoint;
    private getV2AllSpacesEndpoint: IGetV2AllSpacesEndpoint;
    private enableSpacesEndpoint: IEnableSpacesEndpoint;
    private disableSpacesEndpoint: IDisableSpacesEndpoint;
    private searchV2SpaceByIdEndpoint: ISearchV2SpaceByIdEndpoint;
    private updateV2SpaceByIdEndpoint: IUpdateV2SpaceByIdEndpoint;
    private createV2SpaceEndpoint: ICreateV2SpaceEndpoint;

    constructor(
        getV1SpacesEndpoint: GetV1Spaces.IGetV1SpacesEndpoint,
        getV2SpacesEndpoint: GetV2Spaces.IGetV2SpacesEndpoint,
        getV1SpaceByIdEndpoint: GetV1SpaceById.IGetV1SpaceByIdEndpoint,
        searchV1SpaceByIdEndpoint: SearchV1SpaceById.ISearchV1SpaceByIdEndpoint,
        checkInEndpoint: CheckIn.ICheckInEndpoint,
        checkOutEndpoint: CheckOut.ICheckOutEndpoint,
        searchSpacesEndpoint: SearchSpaces.ISearchSpacesEndpoint,
        deleteSpaceEndpoint: IDeleteSpaceEndpoint,
        getV2AllSpacesEndpoint: IGetV2AllSpacesEndpoint,
        enableSpacesEndpoint: IEnableSpacesEndpoint,
        disableSpacesEndpoint: IDisableSpacesEndpoint,
        searchV2SpaceByIdEndpoint: ISearchV2SpaceByIdEndpoint,
        updateV2SpaceByIdEndpoint: IUpdateV2SpaceByIdEndpoint,
        createV2SpaceEndpoint: ICreateV2SpaceEndpoint
    )
    {
        this.getV1SpacesEndpoint = getV1SpacesEndpoint;
        this.getV2SpacesEndpoint = getV2SpacesEndpoint;
        this.getV1SpaceByIdEndpoint = getV1SpaceByIdEndpoint;
        this.searchV1SpaceByIdEndpoint = searchV1SpaceByIdEndpoint;
        this.checkInEndpoint = checkInEndpoint;
        this.checkOutEndpoint = checkOutEndpoint;
        this.searchSpacesEndpoint = searchSpacesEndpoint;
        this.deleteSpaceEndpoint = deleteSpaceEndpoint;
        this.getV2AllSpacesEndpoint = getV2AllSpacesEndpoint;
        this.enableSpacesEndpoint = enableSpacesEndpoint;
        this.disableSpacesEndpoint = disableSpacesEndpoint;
        this.searchV2SpaceByIdEndpoint = searchV2SpaceByIdEndpoint;
        this.updateV2SpaceByIdEndpoint = updateV2SpaceByIdEndpoint;
        this.createV2SpaceEndpoint =  createV2SpaceEndpoint;
    }

    public async getV1Spaces<TSpace>(type: new() => TSpace, nodeId: number, top: number, filter?: SpacesFilter): Promise<TSpace[]>
    {
        return this.getV1SpacesEndpoint.execute(type, nodeId, top, filter);
    }

    public async getV2Spaces<TSelect extends ODataSelect>(query: ODataQueryV2<TSelect>): Promise<PagedResponse<TSelect[]>>
    {
        return this.getV2SpacesEndpoint.execute(query);
    }

    public async getV1SpacesByNode(nodeId: number): Promise<Space[]>
    {
        const query =
            'schema=false&' +
            'showDebugStats=false&' +
            '$top=10000&' +
            '$select=Space_Id,Space_Name,Node_Id,Space_Class,Space_Type,Space_Work_Type,Space_Type_Label,Space_Capacity,Meta_Bookable,Meta_Occ_Type,ImageURI,Meta_Loc_Zone,Booking_Policy_Id,Meta_Serv_Reqs_Catering,Meta_Serv_Reqs_AV,Meta_Serv_Reqs_Hearing,Meta_Serv_Reqs_Presentation,Space_Setup,Space_Layout,Space_Custom_Info,Meta_Ext_Catering_Space_Id,Space_Occupancy,Space_IsEnabled';

        const response: AxiosResponse<Space[]> = await axios.get(`${apis.allApi}${nodeId}/Spaces/search?${query}`);
        return response.data;
    }

    public searchSpaces(nodeId: number, payload: SearchSpaces.ISpaceSearch, skipToken: string | null, top: number): Promise<PagedResponse<SearchSpaces.IAvailableSpace[]>>
    {
        return this.searchSpacesEndpoint.execute(nodeId, payload, skipToken, top);
    }

    public getV1SpaceById(nodeId: number, spaceId: string): Promise<Space | null>
    {
        return this.getV1SpaceByIdEndpoint.execute(nodeId, spaceId);
    }

    public searchV1SpaceById(nodeId: number, spaceId: string): Promise<Space | null>
    {
        return this.searchV1SpaceByIdEndpoint.execute(nodeId, spaceId);
    }

    public searchV2SpaceById(nodeId: number, spaceId: string): Promise<Space>
    {
        return this.searchV2SpaceByIdEndpoint.execute(nodeId, spaceId);
    }

    public updateV2SpaceById(nodeId: number, spaceId: string, payload: ISpace): Promise<Space>
    {
        return this.updateV2SpaceByIdEndpoint.execute(nodeId, spaceId,payload);
    }

    public checkIntoSpace(nodeId: number, spaceId: string, bookingOwnerEmail: string): Promise<void>
    {
        return this.checkInEndpoint.execute(nodeId, spaceId, bookingOwnerEmail);
    }

    public async checkOutOfSpace(nodeId: number, spaceId: string, bookingOwnerEmail: string): Promise<void>
    {
        return this.checkOutEndpoint.execute(nodeId, spaceId, bookingOwnerEmail);
    }

    public deleteSpace(nodeId: number, spaceId: string): Promise<void>
    {
        return this.deleteSpaceEndpoint.execute(nodeId, spaceId);
    }

    public async getV2AllSpaces(nodeId: number, filter?: SpacesFilter): Promise<Space[]>
    {
        return this.getV2AllSpacesEndpoint.execute(nodeId,filter);
    }

    public async enableSpaces(nodeId: number, spaces: NodeIdSpaceId[]): Promise<void>
    {
        return this.enableSpacesEndpoint.execute(nodeId, spaces);
    }

    public async disableSpaces(nodeId: number, spaces: NodeIdSpaceId[]): Promise<void>
    {
        return this.disableSpacesEndpoint.execute(nodeId, spaces);
    }

    public async createV2Space(nodeId: number, payload: ISpace): Promise<Space>
    {
        return this.createV2SpaceEndpoint.execute(nodeId, payload);
    }
}

export interface ISpaceRepository
{
    getV1Spaces<TSpace>(type: new() => TSpace, nodeId: number, top: number, filter?: SpacesFilter): Promise<TSpace[]>;
    getV2Spaces<TSelect extends ODataSelect>(query: ODataQueryV2<TSelect>): Promise<PagedResponse<TSelect[]>>;
    getV1SpacesByNode(nodeId: number): Promise<Space[]>;
    getV1SpaceById(nodeId: number, spaceId: string): Promise<Space | null>;
    searchSpaces(nodeId: number, payload: SearchSpaces.ISpaceSearch, skipToken: string | null, top: number): Promise<PagedResponse<SearchSpaces.IAvailableSpace[]>>;
    searchV1SpaceById(nodeId: number, spaceId: string): Promise<Space | null>;
    searchV2SpaceById(nodeId: number, spaceId: string): Promise<Space>;
    createV2Space(nodeId: number, payload: ISpace): Promise<Space>;
    updateV2SpaceById(nodeId: number, spaceId: string,payload: ISpace): Promise<Space>;
    checkIntoSpace(nodeId: number, spaceId: string, bookingOwnerEmail: string): Promise<void>;
    checkOutOfSpace(nodeId: number, spaceId: string, bookingOwnerEmail: string): Promise<void>;
    deleteSpace(nodeId: number, spaceId: string): Promise<void>;
    getV2AllSpaces(nodeId: number,filter?: SpacesFilter): Promise<Space[]>;
    enableSpaces(nodeId: number, spaces: NodeIdSpaceId[]): Promise<void>;
    disableSpaces(nodeId: number, spaces: NodeIdSpaceId[]): Promise<void>;
}

export class Space
{
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Capacity = 0;
    public Space_Class = "";
    public Space_Type = "";
    public Space_Type_Label = "";
    public Space_Layout = "";
    public Space_Setup = 0;
    public Space_Status = "";
    public ImageURI = "";
    public Booking_Policy_Id = "";
    public Meta_Bookable = 0;
    public Meta_Occ_Type = 0;
    public Meta_Ext_Occ_Count_Mode = 0;
    public Meta_Loc_Zone = "";
    public Meta_Loc_X_m = 0;
    public Meta_Loc_Y_m = 0;
    public Meta_Sol_Thld_Min = 0;
    public Env_Temperature_Range = 0;
    public Env_Sound_Level_Range = 0;
    public Meta_Dig_Sign_Type = 0;
    public Meta_Serv_Reqs_Catering = 0;
    public Meta_Serv_Reqs_AV = 0;
    public Meta_Serv_Reqs_Hearing = 0;
    public Meta_Serv_Reqs_Presentation = 0;
    public Space_Work_Type = "";
    public Space_Custom_Info = "";
    public Space_IsEnabled = 0;
    public Space_Name_Label = "";
    public Env_Zone_Id = 0;
    public Meta_Ext_Catering_Space_Id = [];
    public Meta_Ext_Booking_System = 0;
    public Space_Occupancy = 0;
}

export class LightWeightSpace
{
    public Space_Id = "";
    public Space_Capacity = 0;
    public Space_Occupancy = 0;
    public Meta_Bookable = 0;
}

export class NodeIdSpaceId
{
    public Node_Id = 0;
    public Space_Id = "";
}

export class SpacesFilter
{
    public isAvailable?: boolean;
    public isEnabled?: boolean;
    public workspaceType?: string;
    public spaceType?: string;
    public spaceLastOccupied?: DateTime;
    public spaceStatuses?: string[];
    public policyId?: string;
    public floorId?: number;
    public zone?: string;
    public availableFrom?: DateTime;
    public availableTo?: DateTime;
    public audioVisual?: boolean;
    public presentationAids?: boolean;
    public hearingAids?: boolean;
    public catering?: boolean;
    public linkedSpace?: boolean;
    public layouts?: boolean;
    public minCapacity?: number;
    public metaBookableIs1or3or4or5?: boolean;
    public audioVisualOrPresentationAidsOrHearingAids?: boolean;
    public lastOccupiedFrom?: DateTime;
    public lastOccupiedTo?: DateTime;

    constructor(value: Partial<SpacesFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts: string[] = [];
        filterParts.push(this.isAvailable != null ? `(Space_IsAvailable eq '${this.isAvailable ? 1 : 0}')` : "");
        filterParts.push(this.isEnabled != null ? `(Space_IsEnabled eq ${this.isEnabled ? 1 : 0})` : "");
        filterParts.push(this.workspaceType != null ? `(Space_Work_Type eq '${encodeURIComponent(this.workspaceType)}')` : "");
        filterParts.push(this.spaceType != null ? `(Space_Type eq '${encodeURIComponent(this.spaceType)}')` : "");
        filterParts.push(this.spaceLastOccupied?.isValid? `Space_Last_Occupied gt datetime'${this.spaceLastOccupied.toUTC().toISO()}'` : "");
        filterParts.push(this.spaceStatuses && this.spaceStatuses.length > 0 ? this.spaceStatuses.map((status) => `Space_Status eq '${status}'`).join(' or '): '');
        filterParts.push(this.floorId != null ? `(Node_Id eq ${this.floorId})` : "");
        filterParts.push(this.policyId != null ? `(Booking_Policy_Id eq '${this.policyId}')` : "");
        filterParts.push(this.zone != null ? `(Meta_Loc_Zone eq '${encodeURIComponent(this.zone)}')` : "");
        filterParts.push(this.availableFrom?.isValid && this.availableTo?.isValid ? `AvailableBetween(datetime'${this.availableFrom.toUTC().toISO()}', datetime'${this.availableTo.toUTC().toISO()}')` : "");
        filterParts.push(this.audioVisual ? `Meta_Serv_Reqs_AV eq 1` : "");
        filterParts.push(this.presentationAids ? `Meta_Serv_Reqs_Presentation eq 1` : "");
        filterParts.push(this.hearingAids ? `Meta_Serv_Reqs_Hearing eq 1` : "");
        filterParts.push(this.catering ? `Meta_Serv_Reqs_Catering eq 1` : "");
        filterParts.push(this.linkedSpace && this.layouts ? `Space_Setup eq 5` : "");
        filterParts.push(this.layouts && !this.linkedSpace ? `Space_Setup ge 1` : "");
        filterParts.push(this.minCapacity != null ? `Space_Capacity ge ${this.minCapacity}` : "");
        filterParts.push(this.metaBookableIs1or3or4or5 ? `(Meta_Bookable eq 1 or Meta_Bookable eq 3 or Meta_Bookable eq 4 or Meta_Bookable eq 5)` : "");
        filterParts.push(this.audioVisualOrPresentationAidsOrHearingAids ? `(${'Meta_Serv_Reqs_AV eq 1 or '}${'Meta_Serv_Reqs_Presentation eq 1 or '}${'Meta_Serv_Reqs_Hearing eq 1'})` : "");
        filterParts.push(this.lastOccupiedFrom?.isValid ? `Space_Last_Occupied gt datetime'${this.lastOccupiedFrom.toUTC().toISO()}'` : "");
        filterParts.push(this.lastOccupiedTo?.isValid ? `Space_Last_Occupied lt datetime'${this.lastOccupiedTo.toUTC().toISO()}'` : "");

        return filterParts.filter(i => i != "").join(" and ");
    }
}
