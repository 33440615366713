import React, { Component } from 'react';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import { appContext } from '../../../../AppContext';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Floor } from './DataModel';
import { Box, Grid } from '@mui/material';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssDataGrid from '../../../../Components/Data/DataGrid/IbssDataGrid';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import IbssActionButton, { IActionButton } from '../../../../Components/Buttons/ActionButton/IbssActionButton';
import Helper from '../../../../Common/Helper';

import RefreshIcon from '@mui/icons-material/Refresh';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ConfirmModal from '../../../../Components/Dialogs/ConfirmDialog/ConfirmModal';
import EditFloor from '../Edit/EditFloor';

class ListFloor extends IbssPage<IProps, IState>
{

    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingId: this.appState.buildingId,
            isLoading: false,
            floorList: [],
            openDrawer: false,
            selectedIds: [],
            disableActiveStatusButton: true,
            disableInActiveUpdateStatusButton: true,
            disableRefreshCache: false,
            showConfirmDialog: false,
            currentStatus: '',
            selectedFloorId: 0,
            isEditMode: false
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        this.loadFloorDetails();
    }

    public async componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            await this.buildingChanged(this.state.buildingId)
        }
    }

    private buildingChanged(buildingId: number): void
    {
        if (buildingId)
        {
            this.props.history.push(`/floors/buildingId=${buildingId}`);
        }
        this.setState({ buildingId: buildingId });
        this.loadFloorDetails();
    }

    private async loadFloorDetails(): Promise<void>
    {
        this.setState({ isLoading: true });
        const response = await appContext().ibssApiClientV2.v2.configuration.nodes.get<Floor[]>
            ({
                filter: `Parent_ID eq ${this.state.buildingId}`,
                select: Floor
            })

        const floorList = response.map(item => new FloorView(item));
        this.setState({ floorList: floorList });
        this.selectedSpacesChanged(this.state.selectedIds);
        this.setState({ isLoading: false });
    }

    private handleUpdate(floor: FloorView): React.ReactNode
    {
        return (
            <IbssSvgIcon onClick={() => this.setState({ openDrawer: true, selectedFloorId: floor.id, isEditMode: true })}>
                <CreateIcon />
            </IbssSvgIcon>
        )
    }

    private async refreshCache(): Promise<void>
    {
        this.setState({ disableRefreshCache: true, isLoading: true });
        setTimeout(() => this.setState({ disableRefreshCache: false }), 30000);
        await appContext().ibssApiClientV1.v1.service.refreshCache.get({
            type: 'Spaces'
        });
        await appContext().ibssApiClientV1.v1.service.refreshCache.get({
            type: 'Nodes'
        });
        this.setState({ isLoading: false });
    }

    private async enableFloors(spaceIds: number[]): Promise<void> 
    {
        for (let i = 0; i < spaceIds.length; i++)
        {
            try
            {
                await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.enable.patch({
                    nodeId: spaceIds[i],
                });
            } catch (error)
            {
            }
        }
    }

    private async disableFloors(spaceIds: number[]): Promise<void> 
    {
        for (let i = 0; i < spaceIds.length; i++)
        {
            try
            {
                await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.disable.patch({
                    nodeId: spaceIds[i],
                });
            } catch (error)
            {
            }
        }
    }

    private async deleteFloors(spaceIds: number[]): Promise<void> 
    {
        for (let i = 0; i < spaceIds.length; i++)
        {
            try
            {
                await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.delete({
                    nodeId: spaceIds[i],
                });
            } catch (error)
            {
            }
        }
    }


    private async updateFloorStatus(status: string): Promise<void> 
    {
        this.setState({ isLoading: true });
        const spaces = this.state.floorList.filter(x => this.state.selectedIds.includes(x.id));
        const spaceIds = spaces.map(x => x.id);

        if (status === 'active') 
        {
            await this.enableFloors(spaceIds);
        } else if (status === 'inActive') 
        {
            await this.disableFloors(spaceIds);
        } else if (status === 'cancel') 
        {
            await this.deleteFloors(spaceIds);
        }

        this.setState({ isLoading: false, showConfirmDialog: false });
        await this.loadFloorDetails();
    }

    private selectedSpacesChanged(floorId: GridRowSelectionModel): void 
    {
        this.setState({ selectedIds: floorId });

        const selectedFloorData = this.state.floorList.filter(x => floorId.includes(x.id));
        const activeFloorSelected = selectedFloorData.some(x => x.IsEnabled == 1);
        const inactiveFloorSelected = selectedFloorData.some(x => x.IsEnabled == 0);

        if (activeFloorSelected && inactiveFloorSelected)
        {
            this.setState({
                disableActiveStatusButton: true,
                disableInActiveUpdateStatusButton: true
            });
        }
        else if (activeFloorSelected)
        {
            this.setState({
                disableActiveStatusButton: true,
                disableInActiveUpdateStatusButton: false
            });
        }
        else if (inactiveFloorSelected)
        {
            this.setState({
                disableActiveStatusButton: false,
                disableInActiveUpdateStatusButton: true
            });
        }
        else
        {
            this.setState({
                disableActiveStatusButton: true,
                disableInActiveUpdateStatusButton: true
            });
        }
    }

    private async showConfirmModal(status: string): Promise<void>
    {
        this.setState({
            showConfirmDialog: true,
            currentStatus: status
        });
    }

    private closeDrawer(): void
    {
        this.setState({
            openDrawer: false,
            selectedFloorId: 0,
            isEditMode: false
        });
    }

    public render(): JSX.Element
    {

        const columns: GridColDef<FloorView>[] =
            [
                {
                    field: Helper.nameOf<FloorView>("displayName"),
                    headerName: this.labels.HubLabelDisplayName,
                    flex: 1
                },
                {
                    field: Helper.nameOf<FloorView>("name"),
                    headerName: this.labels.funcFloorName_S,
                    flex: 1
                },
                {
                    field: Helper.nameOf<FloorView>("id"),
                    headerName: this.labels.funcFloorID_S,
                    flex: 1
                },
                {
                    field: Helper.nameOf<FloorView>("IsEnabled"),
                    headerName: this.labels.HubLabelStatus,
                    flex: 1,
                    renderCell: (params) => <>{params.row.IsEnabled == 0 ? this.labels.HubLabelInActive : this.labels.HubLabelActive}</>
                },
                {
                    headerName: this.labels.HubLabelAction,
                    minWidth: 120,
                    field: "",
                    flex: 1,
                    filterable: false,
                    sortable: false,
                    renderCell: (params) => <>{this.handleUpdate(params.row)}</>
                }
            ];

        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.funcSpaceRefreshCache_S,
                    icon: (
                        <IbssSvgIcon fontSize='small' className="mr-1">
                            <RefreshIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.refreshCache(),
                    disabled: this.state.disableRefreshCache,
                },
                {
                    label: this.labels.funcCateringMenuEditDelete_S,
                    icon: (
                        <IbssSvgIcon fontSize='small' className="mr-1">
                            <CancelIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.showConfirmModal("cancel"),
                    disabled: this.state.selectedIds.length <= 0,
                },
                {
                    label: this.labels.HubLabelActive,
                    icon: (
                        <IbssSvgIcon fontSize='small' className="mr-1">
                            <CheckCircleIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.showConfirmModal("active"),
                    disabled: this.state.disableActiveStatusButton,
                },
                {
                    label: this.labels.HubLabelInActive,
                    icon: (
                        <IbssSvgIcon fontSize='small' className="mr-1">
                            <CancelIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.showConfirmModal("inActive"),
                    disabled: this.state.disableInActiveUpdateStatusButton,
                },
                {
                    label: this.labels.HubButtonAdd,
                    className: "addIcon",
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    disabled: false,
                    onClick: () => this.setState({
                        openDrawer: true
                    }),
                },

            ];

        return (
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    <div className="table-panel">
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box className='d-flex'>
                                        <Box className="mr-2">
                                            <IbssSvgIcon fontSize='large'>
                                                {Icons.StandardDesk}
                                            </IbssSvgIcon>
                                        </Box>
                                        <Box className='table-panel-header' style={{ paddingTop: '4px' }}>
                                            {this.labels.HubMenuFloorLabel}
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                        <IbssActionButton buttons={actionButtons} />
                                    </Box>
                                    {
                                        <div>
                                            <IbssDataGrid
                                                loading={this.state.isLoading}
                                                className="view-floors-data-grid"
                                                disableRowSelectionOnClick
                                                checkboxSelection
                                                columns={columns}
                                                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.selectedSpacesChanged(rowSelectionModel)}
                                                rows={this.state.floorList}
                                                pageSizeOptions={[100]}
                                            />
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <ConfirmModal
                            show={this.state.showConfirmDialog}
                            modalHeading={this.labels.HubLabelModalConfirmTitle}
                            modalMessage={this.labels.HubLabelModalConfirmTitle}
                            handleModal={() => this.setState({ showConfirmDialog: false })}
                            okButton={() => this.updateFloorStatus(this.state.currentStatus)}
                        />
                        <EditFloor
                            buildingId={this.state.buildingId}
                            selectedFloorId={this.state.selectedFloorId}
                            open={this.state.openDrawer}
                            onClose={() => this.closeDrawer()}
                            loadFloorDetails={() => this.loadFloorDetails()}
                            selectedIdChanged={(id: number) => this.setState({ selectedFloorId: id })}
                            isEditMode={this.state.isEditMode}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ListFloor);



export interface IQueryParams
{
    buildingid: string;
}

interface IProps extends RouteComponentProps<IQueryParams> 
{
}

interface IState 
{
    buildingId: number;
    isLoading: boolean;
    floorList: FloorView[];
    openDrawer: boolean;
    disableRefreshCache: boolean,
    selectedIds: GridRowSelectionModel;
    disableActiveStatusButton: boolean;
    disableInActiveUpdateStatusButton: boolean;
    showConfirmDialog: boolean;
    currentStatus: string;
    selectedFloorId: number;
    isEditMode: boolean;
}


export class FloorView 
{
    public id: number;
    public displayName: string;
    public name: string;
    public sortOrder: string;
    public IsEnabled: number;


    constructor(value: Floor) 
    {
        this.id = value.ID;
        this.displayName = value.DisplayName;
        this.name = value.Name;
        this.sortOrder = value.SortOrder;
        this.IsEnabled = value.IsEnabled;
    }
}