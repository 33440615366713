import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { DataEntryRole } from "./RoleRepository";
import { IbssRequestConfig } from "../ApiClient";

export class GetAllEndpoint implements IGetAllEndpoint
{
    public async execute(suppressErrorPopup: boolean): Promise<DataEntryRole[]>
    {
        try
        {
            const axiosConfig: IbssRequestConfig = { suppressErrorPopup: suppressErrorPopup };
            const response = await axios.get<DataEntryRole[]>(`${apis.dataEntrySecurityApi}/Roles`, axiosConfig);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetAllEndpoint
{
    execute(suppressErrorPopup: boolean): Promise<DataEntryRole[]>;
}
