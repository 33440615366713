import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssSwitchLabel from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { appContext } from '../../../../AppContext';

class BuildingOverviewForm extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <div>
                <IbssTextField className='mt-1' variant='outlined' label={this.labels.funcBuildingOwner_S} fullWidth value={this.props.owner} onChange={(e) => this.props.ownerChanged(e.target.value)} />
                <IbssTextField className='mt-1' variant='outlined' label={this.labels.funcBuildingOperator_S} fullWidth value={this.props.operator} onChange={(e) => this.props.operatorChanged(e.target.value)} />
                <IbssTextField disabled className='mt-1' variant='outlined' label={this.labels.funcBuildingID_S} fullWidth value={this.props.id} />
                <IbssTextField required className='mt-1' variant='outlined' label={this.labels.funcBuildingName_S} fullWidth value={this.props.name} onChange={(e) => this.props.nameChanged(e.target.value)} />
                <IbssTextField className='mt-1' variant='outlined' label={this.labels.funcBuildingOccupier_S} fullWidth value={this.props.occupier} onChange={(e) => this.props.occupierChanged(e.target.value)} />
                <IbssTextField className='mt-1' variant='outlined' label={this.labels.funcBuildingSize_S} fullWidth value={this.props.size} onChange={(e) => this.props.sizeChanged(e.target.value)} />
                <IbssTextField className='mt-1' variant='outlined' label={this.labels.funcBuildingCapacity_S} fullWidth value={this.props.capacity} onChange={(e) => this.props.capacityChanged(e.target.value)} />
                <IbssTextField type='number' className='mt-1' variant='outlined' label={this.labels.funcBuildingLongitude_S} fullWidth value={this.props.longitude} onChange={(e) => this.props.longitudeChanged(e.target.value)} />
                <IbssTextField type='number' className='mt-1' variant='outlined' label={this.labels.funcBuildingLatitude_S} fullWidth value={this.props.latitude} onChange={(e) => this.props.latitudeChanged(e.target.value)} />
                <div className='d-flex mt-1' style={{ justifyContent: 'space-between' }}>
                    <div>{this.labels.funcActiveSpace_S}</div>
                    <IbssSwitchLabel checked={this.props.activeSpace} onChange={(e) => this.props.activeSpaceChanged(e.target.checked)} />
                </div>
            </div>
        )
    }
}

export default BuildingOverviewForm;

export interface IProps
{
    owner: string;
    ownerChanged: (value: string) => void;
    operator: string;
    operatorChanged: (value: string) => void;
    id: string;
    name: string;
    nameChanged: (value: string) => void;
    occupier: string;
    occupierChanged: (value: string) => void;
    size: string;
    sizeChanged: (value: string) => void;
    capacity: string;
    capacityChanged: (value: string) => void;
    longitude: string;
    longitudeChanged: (value: string) => void;
    latitude: string;
    latitudeChanged: (value: string) => void;
    activeSpace: boolean;
    activeSpaceChanged: (value: boolean) => void;
}

export interface IState
{
}