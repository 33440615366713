import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class Booking extends ODataSelect
{
    public Node_Id = 0;
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Description = "";
    public Booking_Start = "";
    public Booking_End = "";
    public Booking_Completed_Time = "";
    public Booking_Early_Checkin = "";
    public Booking_Status: BookingStatus = "";
    public Booking_Owner_Name = "";
    public Booking_Owner_Email = "";
    public Booking_IsActive = 0;
    public Booking_IsCancelled = 0;
    public Booking_IsCheckedIn = 0;
    public Booking_AutoCheckIn = 0;
    public Booking_IsEarly_Checkin = 0;
    public Booking_IsLate_Checkin = 0;
    public Booking_Share_Loc = 0;
    public Booking_IsApproved = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Space_Layout = "";
    public _CreatedAt = "";
    public _CreatedBy = "";
    public Booking_Parties = "";
    public Booking_Series_Id = ""
    public Parent_Booking_Id = "";
    public Parent_Booking_Relation_Type = ParentBookingRelationTypeEnum.Main;
    public Booking_Resources = [];
    public Meta_Loc_Zone = '';
}

export type BookingStatus = ("" | "Cancelled" | "No Show" | "Auto Cancelled" | "Completed" | "Amended" | "New" | "Checked In"  | "Late Checkin" | "Early Checkin" | "In Progress" | "Active");

export enum ParentBookingRelationTypeEnum
{
    Main = 'BookingRelationTypeMain',
    Setup = 'BookingRelationTypeSetup',
    Teardown = 'BookingRelationTypeTearDown'
}

export interface IBookingPolicy 
{
    Node_Id: number;
    Booking_Policy_Id: string;
    Booking_Policy_Name: string;
    Booking_Policy: {
        PolicyType: string;
        BuildingId: string;
        FloorId: string;
        Allowed_TimeRange: string;
        Booking_Policy_Description: string;
        BookingSlots: 
        {
            ExcludedDates: 
            {
                StartDate: string;
                EndDate: string;
                StartTime: string;
                EndTime: string;
            }[];
        };
    };
}

export class BuildingBookingPolicy extends ODataSelect 
{
    public Node_Id = 0;
    public Booking_Policy_Id = "";
    public Booking_Policy_Name = "";
    public Booking_Policy: IBookingPolicyObject = 
    {  
        PolicyType: "",
        BuildingId: "",
        FloorId: "",
        Allowed_TimeRange: "",
        Booking_Policy_Description: "",
        BookingSlots: 
        {
            ExcludedDates: [] as IExcludedDate[],
        }
    };
}

export interface IBookingPolicyObject 
{
    PolicyType: string;
    BuildingId: string;
    FloorId: string;
    Allowed_TimeRange: string;
    Booking_Policy_Description: string;
    BookingSlots: IBookingSlots;
}

export interface IBookingSlots 
{
    ExcludedDates: IExcludedDate[];
}

export interface IExcludedDate 
{
    StartDate: string;
    EndDate: string;
    StartTime: string;
    EndTime: string;
}


export class V2BookingResponse extends ODataSelect
{
    public Booking_Resources: IBookingResources[] = [];

    public toSelect(): string
    {
        return ''; // endpoint returns no data if we provode a $select
    }
}

export interface IBookingResources
{
    Record_Id: string;
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Resource_Id: string;
    Booking_Visitor: boolean;
    Booking_Participant_Type: number;
}
