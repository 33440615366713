import { Component } from "react";
import { appContext } from "../../../AppContext";
import IbssSvgIcon from "../../Icons/SvgIcon/IbssSvgIcon";
import { Icons } from "../../../Common/AllsvgIcons";
import IbssDialog from "../../Dialogs/BaseDialog/IbssDialog";
import { Box, Divider, Typography } from "@mui/material";
import IbssButtonRedo from "../../Buttons/Button/IbssButton";
import { Button } from '@mui/material';
import { isEqual } from "lodash";
import IbssHorizontalTabs, { TabsParameters } from "../../Layout/Tabs/IbssTabs";
import EmployeeTabView from "./EmployeeTabView";
import VisitorTabView from "./VisitorTabView";
import IbssAvatar from "../../Miscellaneous/Avatar/IbssAvatar";
import { IDelegate } from "../../../Providers.Api/Delegates/GetManyByDelegatorEndpoint";
import IbssCheckBox from "../../Inputs/CheckBox/IbssCheckBox";
import "./EmployessOrVisitor.scss";
import * as UserPickerModule from "../../Inputs/UserPicker/UserPicker";
import React from "react";

export default class EmployeeOrVisitorPicker extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get local() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);

        this.state = {
            showModal: false,
            isVisitor: false,
            noOnBehalfOfSelectedError: '',
            onBehalfOfUser: '',
            nonVisitorOnBehalfOfData: { email: "", firstName: "", lastName: "", company: "", displayName: "" },
            alreadyPresentError: '',
            visitorOnBehalfOfData: { email: "", firstName: "", lastName: "", company: "" },
            isDisabled: false,
            errors: '',
            bookingAutoCheckin: 0,
            onBehalfOf: '',
            onBehalfOfData: { email: "", firstName: "", lastName: "", company: "", isVisitor: false },
            useOnBehalfOfCostCodes: 0,
            selectedUser: '',
            visitorTypeTabIndex: 0
        }
    }

    public componentDidUpdate(prevProps: IProps): void
    {
        // if props.onBehalfOfData is defined and has changed.
        if (this.props.onBehalfOfData && !isEqual(prevProps?.onBehalfOfData, this.props?.onBehalfOfData))
        {
            this.setState({
                onBehalfOfData: this.props.onBehalfOfData,
                onBehalfOfUser: this.props.onBehalfOfData.email,
            });
        }
    }

    private handleBookingForEmployee(): void
    {
        this.setState({
            isVisitor: false
        })
    }

    private handleBookingForVisitor(): void
    {
        this.setState({
            isVisitor: true,
        })
    }

    private bookingOnBehalfOfEmailChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        let errors: string = '';
        const pattern = new RegExp(
            /^(("[\w-\s']+")|([\w-']+(?:\.[\w-']+)*)|("[\w-\s']+")([\w-']+(?:\.[\w-']+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!pattern.test(e.target.value))
        {
            // update error massage in errors.email  object
            errors = this.labels.HubLabelInvalidEmailAddress;;
        }
        // updated state for email errors
        if (errors === "")
        {
            this.setState({
                isDisabled: false
            })
        } else
        {
            this.setState({
                isDisabled: true
            })
        }
        this.setState({
            errors: errors,
        });

        this.setState({
            visitorOnBehalfOfData: {
                ...this.state.visitorOnBehalfOfData,
                email: e.target.value,
            },
            noOnBehalfOfSelectedError: ""            
        })
    }

    private bookingOnBehalfOfFirstNameChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        this.setState({
            visitorOnBehalfOfData: {
                ...this.state.visitorOnBehalfOfData,
                firstName: e.target.value,
            },
            noOnBehalfOfSelectedError: ""
        })
    }

    private bookingOnBehalfOfLastNameChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        this.setState({
            visitorOnBehalfOfData: {
                ...this.state.visitorOnBehalfOfData,
                lastName: e.target.value,
            },
            noOnBehalfOfSelectedError: ""
        })
    }

    private bookingOnBehalfOfCompanyChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        this.setState({
            visitorOnBehalfOfData: {
                ...this.state.visitorOnBehalfOfData,
                company: e.target.value,
            },
            noOnBehalfOfSelectedError: ""
        })
    }

    private setOnBehalfOfCostCodesChecked(e: React.ChangeEvent<HTMLInputElement>): void 
    {
        this.setState({
            useOnBehalfOfCostCodes: e.target.checked ? 1 : 0
        });
    };

    private setChecked(e: React.ChangeEvent<HTMLInputElement>, name: string): void
    {
        if (name === 'bookingAutoCheckin' && this.state.bookingAutoCheckin === 1)
        {
            this.setState({ bookingAutoCheckin: 0 });
        }
        if (name === 'bookingAutoCheckin' && this.state.bookingAutoCheckin === 0)
        {
            this.setState({ bookingAutoCheckin: 1 });
        }
    };

    private async handleAddBookingFor(): Promise<void>
    {
        if ((this.state.nonVisitorOnBehalfOfData.email !== "" || this.state.visitorOnBehalfOfData.email !== "") && this.state.errors === "")
        {
            if (this.state.visitorTypeTabIndex == 0)
            {
                await this.setState({
                    onBehalfOf: this.state.nonVisitorOnBehalfOfData.email,
                    onBehalfOfUser: this.state.nonVisitorOnBehalfOfData.email,
                    selectedUser: this.state.nonVisitorOnBehalfOfData.email,
                    onBehalfOfData:
                    {
                        email: this.state.nonVisitorOnBehalfOfData.email,
                        firstName: this.state.nonVisitorOnBehalfOfData.firstName,
                        lastName: this.state.nonVisitorOnBehalfOfData.lastName,
                        company: this.state.nonVisitorOnBehalfOfData.company,
                        isVisitor: false,
                    },
                    visitorOnBehalfOfData: { email: "", firstName: "", lastName: "", company: "" },
                })
            }
            if(this.state.visitorTypeTabIndex == 1)
            {
                await this.setState({
                    onBehalfOf: this.state.visitorOnBehalfOfData.email,
                    onBehalfOfUser: this.state.visitorOnBehalfOfData.email,
                    selectedUser: this.state.visitorOnBehalfOfData.email, 
                    onBehalfOfData:
                    {
                        email: this.state.visitorOnBehalfOfData.email,
                        firstName: this.state.visitorOnBehalfOfData.firstName,
                        lastName: this.state.visitorOnBehalfOfData.lastName,
                        company: this.state.visitorOnBehalfOfData.company,
                        isVisitor: true,
                    },
                    nonVisitorOnBehalfOfData: { email: "", firstName: "", lastName: "", company: "", displayName: "" },
                })
            }
            this.handleShowHideBookingForModal();
        }
        else
        {
            this.setState({ 
                noOnBehalfOfSelectedError: this.labels.HubLabelNoOnBehalfSelcted, 
                isDisabled: true 
            });
        }

        this.props.onSubmit(this.state.onBehalfOf, this.state.onBehalfOfData, this.state.bookingAutoCheckin, this.state.useOnBehalfOfCostCodes);
    }

    private async handleShowHideBookingForModal(): Promise<void>
    {
        await this.setState({
            showModal: !this.state.showModal,
        })
        this.setState(
            {
                noOnBehalfOfSelectedError: "",
                onBehalfOfUser: this.state.showModal === false ? this.state.onBehalfOfUser : "",
                onBehalfOf: this.state.showModal === false ? this.state.onBehalfOf : "",
            })
    };

    private async favouriteClicked(selectedFavourite: IFavouriteUser): Promise<void>
    {
        this.setState({ onBehalfOfUser: selectedFavourite.email });
    }

    private async delegateClicked(selectedDelegate: IDelegate): Promise<void>
    {
        this.setState({ onBehalfOfUser: selectedDelegate.PrimaryEmail ?? "" });
    }

    private async employeeChanged(employee: UserPickerModule.IUser | null): Promise<void>
    {
        if (employee)
        {
            this.setState({
                nonVisitorOnBehalfOfData: {
                    email: employee.email,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    company: employee.company,
                    displayName: employee.displayName
                },
                noOnBehalfOfSelectedError: "",
                isDisabled: false
            })
        }
        else
        {
            this.setState({
                nonVisitorOnBehalfOfData: {
                    email: "",
                    firstName: "",
                    lastName: "",
                    company: "",
                    displayName: ""
                },
                isDisabled: true
            })
        }
    }

    public render(): JSX.Element
    {    
        const hasOnBehalfAutoCheckinRights = this.local.permissionAppliesToBuilding('API.Bookings.BookOnBehalfAutoCheckinOption', this.props.buildingId) && this.local.hasRight("API.Bookings.BookOnBehalfAutoCheckinOption");

        const tabs: TabsParameters[] = [
            {
                label: this.labels.HubLabelEmployee,
                components: (
                    <>
                        <EmployeeTabView
                            employeeName={this.state.onBehalfOfUser || this.props.firstNameData || ""}
                            onChange={async text => this.setState({ onBehalfOfUser: text, isDisabled : !text })}
                            onEmployeeChange={employee => this.employeeChanged(employee)}
                            error={this.state.alreadyPresentError}
                        />
                        <div className="text-danger">{this.state.noOnBehalfOfSelectedError && this.state.noOnBehalfOfSelectedError}</div>
                        {hasOnBehalfAutoCheckinRights &&
                            <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
                                <IbssCheckBox
                                    label={this.labels.HubLabelEmployeeAutoCheckInRoamerAccess}
                                    disabled={this.state.nonVisitorOnBehalfOfData.email === ""}
                                    checked={this.state.bookingAutoCheckin === 1}
                                    onClicked={(e: React.ChangeEvent<HTMLInputElement>) => this.setChecked(e, "bookingAutoCheckin")}
                                />
                            </Box>
                        }
                        <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
                            <IbssCheckBox
                                label={this.labels.HubLabelEmployeeDefaultCostCodesForBooking}
                                checked={this.state.useOnBehalfOfCostCodes === 1}
                                disabled={this.state.nonVisitorOnBehalfOfData.email === "" && this.state.useOnBehalfOfCostCodes === 0}
                                onClicked={(e: React.ChangeEvent<HTMLInputElement>) => this.setOnBehalfOfCostCodesChecked(e)}
                            />
                        </Box>
                    </>
                ),
                tabButtonHeight: "40px",
            },
            {
                label: this.labels.HubLabelvisitor,
                components: (
                    <>
                        <VisitorTabView
                            onEmailChange={e => this.bookingOnBehalfOfEmailChanged(e)}
                            email={this.state.visitorOnBehalfOfData.email}
                            onFirstNameChange={e => this.bookingOnBehalfOfFirstNameChanged(e)}
                            firstName={this.state.visitorOnBehalfOfData.firstName}
                            onLastNameChange={e => this.bookingOnBehalfOfLastNameChanged(e)}
                            lastName={this.state.visitorOnBehalfOfData.lastName}
                            onCompanyNameChange={e => this.bookingOnBehalfOfCompanyChanged(e)}
                            companyName={this.state.visitorOnBehalfOfData.company}
                            errors={this.state.errors}
                        />
                        <div className="text-danger">{this.state.noOnBehalfOfSelectedError && this.state.noOnBehalfOfSelectedError}</div>
                        {this.local.hasRight("API.Bookings.BookOnBehalfAutoCheckinOption") &&
                            <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
                                <IbssCheckBox
                                    label={this.labels.HubLabelVisitorAutoCheckInRoamerAccess}
                                    checked={this.state.bookingAutoCheckin === 1}
                                    onClicked={(e: React.ChangeEvent<HTMLInputElement>) => this.setChecked(e, "bookingAutoCheckin")}
                                />
                            </Box>
                        }
                    </>
                ),
                tabButtonHeight: "40px",
            },
        ];

        const delegateTabs: TabsParameters[] = [
            {
                label: this.labels.HubLabelFavourites,
                components: (
                    <Box className="someone-else-delegated-tab">
                        {this.props.favourites &&
                            this.props.favourites.map((item) => (
                                <>
                                    <Box display={'flex'} alignItems={'center'} my={3} className="pointer" onClick={() => this.favouriteClicked(item)}>
                                        <IbssAvatar fullName={`${item.firstName} ${item.lastName}`} />
                                        <Typography pl={2}>{`${item.firstName} ${item.lastName}`}</Typography>
                                    </Box>
                                </>
                            ))}
                    </Box>
                ),
                tabButtonHeight: "40px",
            },
            {
                label: this.labels.HubLabelDelegatedBy,
                components: (
                    <Box className="someone-else-delegated-tab">
                        {this.props.delegatedBy &&
                            this.props.delegatedBy.map((item) => (
                                <>
                                    <Box display={'flex'} alignItems={'center'} my={3} className="pointer" onClick={() => this.delegateClicked(item)}>
                                        <IbssAvatar fullName={item.PrimaryDisplayName ? item.PrimaryDisplayName : ""} />
                                        <Typography pl={2}>{item.PrimaryDisplayName}</Typography>
                                    </Box>
                                </>
                            ))}
                    </Box>
                ),
                tabButtonHeight: "40px",
            },
        ];

        const employeeTabs = tabs.filter((item) => item.label === "Employee");
        const visitorTabs = tabs.filter((item) => item.label === this.labels.HubLabelvisitor);
        const delegatedTabs = delegateTabs.filter((item) => item.label === "Delegated By");

        return (
            <>
              <Button
                disabled={this.props.disabled}
                fullWidth
                sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize', cursor: 'pointer' }}
                className="mt-1"
                onClick={() => this.setState({ showModal: true })}>
                <Box className="icon-text-inline pl-0" sx={{ width: '62%' }}>
                    <Box mr={1}>
                    <IbssSvgIcon fontSize='inherit' className="mr-1" sx={{ color: (theme) => theme.palette.text.primary }}>
                        {Icons.UserIcon}
                    </IbssSvgIcon>
                    </Box>
                    <Box className="space-text-item col-text" sx={{ whiteSpace: 'nowrap'}}>
                        {this.props.forVisitor == true ? this.labels.funcBookForAVistor_S : this.labels.HubLabelBookForSomeone}
                    </Box>
                </Box>
                <Box className="d-flex" style={{ width: '38%', justifyContent: 'right', alignItems: 'center' }}>
                    <Box width={'100%'} display={'flex'} justifyContent={"space-between"} alignItems={'center'}>
                    <Box sx={{width:'calc(100% - 28px);'}}>
                        {this.state.selectedUser ? 
                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }} noWrap>
                            {this.state.selectedUser}
                        </Typography> : 
                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }} noWrap>
                            {this.props.firstNameData}
                        </Typography>
                        }
                    </Box>
                    <Box sx={{lineHeight:'1'}}>
                        <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                        {Icons.RightArrowIcon}
                        </IbssSvgIcon>
                    </Box>
                    </Box>
                </Box>
                </Button>
                <Divider className="my-1"/>
                {
                    this.state.showModal &&
                    <IbssDialog
                        aria-modal="true"
                        aria-label="book on behalf of modal"
                        open={this.state.showModal}
                        fullWidth
                        header=
                        {
                            <>
                                <label className="modal-heading">{this.labels.HubLabelBookOnBehalfHeading}</label>
                            </>
                        }
                        dialogContent=
                        {
                            <div>

                                <div className="d-flex justify-content-between">
                                    <label className="lable-modal">{this.labels.HubLabelChooseBookingSpaceFor}</label>
                                    {/* <input name="isPrivate" type="checkbox" className="input-check-box-label border checkbox-sm" checked={this.state.isVisitor} onChange={() => this.handleChangeVisitor()} /> */}
                                </div>
                                <div className="mb-3">
                                    <IbssHorizontalTabs
                                        defaultValue={this.state.visitorTypeTabIndex}
                                        tabChanged={(tabIndex) => this.setState({ visitorTypeTabIndex: tabIndex })}
                                        tabs={this.props.forVisitor ? visitorTabs : this.local.hasRight("API.Bookings.BookOnBehalfOf") ? tabs : employeeTabs}
                                        orientation="horizontal"
                                        boxwidth='100%'
                                    />
                                </div>
                                {(this.local.hasRight("API.Bookings.V2") && this.props.delegatedBy) && this.props.forVisitor != true &&
                                    <div className="mb-2">
                                        <IbssHorizontalTabs
                                            tabs={this.local.hasRight("API.Bookings.BookOnBehalfOf") ? delegateTabs : delegatedTabs}
                                            className="someone-else-delegated-tab"
                                            orientation="horizontal"
                                            boxwidth='100%'
                                        />
                                    </div>
                                }
                            </div>
                        }
                        footer=
                        {
                            <div className="px-58 w-100 d-flex justify-content-center btn-block">
                                <IbssButtonRedo
                                    variant='contained'
                                    sx={{ width: '50%', ml: 0.5 }}
                                    onClick={() => this.handleAddBookingFor()}
                                    disabled={this.state.isDisabled}
                                >
                                    {this.labels.HubButtonSave}
                                </IbssButtonRedo>
                            </div>
                        }
                        onClose={() => this.handleShowHideBookingForModal()}
                    />
                }
            </>
        );
    }
}

export interface IState
{
    showModal: boolean;
    isVisitor: boolean;
    noOnBehalfOfSelectedError: string;
    onBehalfOfUser: string;
    nonVisitorOnBehalfOfData: INonVisitorOnBehalfOfData;
    alreadyPresentError: string;
    visitorOnBehalfOfData: IVisitorOnBehalfOfData;
    isDisabled: boolean;
    errors: string;
    bookingAutoCheckin: number;
    onBehalfOf: string;
    onBehalfOfData: IOnBehalfOf;
    useOnBehalfOfCostCodes: number;
    selectedUser: string;
    visitorTypeTabIndex: number;
}

export interface IProps
{
    onSubmit: Function;
    onBehalfOfData?: IOnBehalfOf;
    firstNameData?: string;
    delegatedBy?: IDelegate[] | undefined;
    favourites?: IFavouriteUser[] | undefined;
    BookingId?: string;
    buildingId: number;
    disabled?: boolean;
    forVisitor?: boolean;
}

export interface IFavouriteUser
{
    email: string;
    firstName: string;
    lastName: string;
}

export interface INonVisitorOnBehalfOfData
{
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    displayName: string;
}

export interface IVisitorOnBehalfOfData
{
    email: string;
    firstName: string;
    lastName: string;
    company: string;
}

export interface IOnBehalfOf
{
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    isVisitor: boolean;
}
