import { DateTime } from "luxon";
import { appContext } from "../AppContext";
import { BookingFilter, BookingFilter2 } from "../Providers.Api/Bookings/BookingRepository";
import { ICreateBookingResponse, ICreateV1BookingRequest } from "../Providers.Api/Bookings/CreateV1BookingEndpoint";
import { ICreateV2BookingRequest } from "../Providers.Api/Bookings/CreateV2BookingEndpoint";
import { IDeleteBookingResponse } from "../Providers.Api/Bookings/DeleteV1BookingEndpoint";
import { IGetV2BookingResponse, IGetV2BookingResponse_BookingParty } from "../Providers.Api/Bookings/GetV2BookingEndpoint";
import { IUpdateBookingResponse, IUpdateV1BookingRequest } from "../Providers.Api/Bookings/UpdateV1BookingEndpoint";
import { IUpdateV2BookingRequest } from "../Providers.Api/Bookings/UpdateV2BookingEndpoint";
import * as GetV2Bookings from "../Providers.Api/Bookings/GetV2BookingsEndpoint";
import { ODataQuery } from "../Providers.Api/ODataQuery";
import { PagedResponse } from "../Providers.Api/Models";
import * as GetMyV2BookingsForOthers from "../Providers.Api/Bookings/GetMyV2BookingsForOthersEndpoint";
import Helper from "../Common/Helper";

export class BookingService implements IBookingService
{
    private get api() { return appContext().apiClient; }
    private get local() { return appContext().localStorageProvider; }
    private get hasV2Rights() { return appContext().localStorageProvider.hasRight("API.Bookings.V2"); }

    public async get(nodeId: number, bookingId: string): Promise<IGetV2BookingResponse>
    {
        if(this.hasV2Rights)
        {
            return await this.api.bookings.getV2Booking(nodeId, bookingId);
        }
        else
        {
            const booking = await this.api.bookings.getV1Booking(nodeId, bookingId);

            const bookingParties = booking.Booking_Parties.map(x =>
            ({
                Booking_Participant_Email: x.Email,
                Booking_Participant_Name: x.First_Name + ' ' + x.Last_Name,
                Booking_Participant_Organisation: x.Company,
                Booking_Resource_Id: null,
                Booking_Visitor: false,
                Booking_Participant_Type: 1,
            } as IGetV2BookingResponse_BookingParty));
            
            const bookingPartiesVisitors = booking.Booking_Parties_Visitors.map(x =>
            ({
                Booking_Participant_Email: x.Email,
                Booking_Participant_Name: x.First_Name + ' ' + x.Last_Name,
                Booking_Participant_Organisation: x.Company,
                Booking_Resource_Id: null,
                Booking_Visitor: true,
                Booking_Participant_Type: 2,
            } as IGetV2BookingResponse_BookingParty));

            const v2Booking: IGetV2BookingResponse =
            {
                ...booking,
                Booking_Resources: [],
                Booking_Parties: [...bookingParties, ...bookingPartiesVisitors],
                Booking_Attendance: 0
            };

            return v2Booking;
        }
    }

    public async getBookings(query: ODataQuery, startTime?: DateTime, endTime?: DateTime, spaceIds?: Array<string>): Promise<PagedResponse<GetV2Bookings.Booking[]>>
    {
        if(!this.hasV2Rights)
        {
            throw new Error("V1 bookings are not supported.");
        }

        const displayName = this.local.getUserDetails().displayName;
        const response = await  this.api.bookings.getV2Bookings(query, startTime, endTime, spaceIds);
            
        response.value.forEach(i =>
        {
            if (!i.Booking_Owner_Name)
            {
                i.Booking_Owner_Name = displayName;
            }
        });

        return response;
    }

    public async create(nodeId: number, booking: ICreateV2BookingRequest, suppressErrorPopup?: boolean): Promise<ICreateBookingResponse>
    {
        if (this.hasV2Rights)
        {
            const response = await this.api.bookings.createV2Booking(nodeId, booking, suppressErrorPopup ?? false);
            const bookingResponse = {
                Node_Id: response.Booking.Node_Id,
                Booking_Id: response.Booking.Booking_Id,
                Space_Id: response.Booking.Space_Id,
                Booking_Start: response.Booking.Booking_Start,
                Booking_End: response.Booking.Booking_End,
                Booking_Details: response.Booking.Booking_Details,
                Failed_Bookings_Details: response.Booking.Failed_Bookings_Details
            }
            return bookingResponse;
        }
        else
        {
            const v1Booking: ICreateV1BookingRequest =
            {
                ...booking,
                Booking_Parties: JSON.stringify(booking.Booking_Parties?.filter(x => x.Booking_Participant_Type == 1).map(x => (
                {
                    Email: x.Booking_Participant_Email,
                    First_Name: x.Booking_Participant_Name?.split(' ')[0],
                    Last_Name: x.Booking_Participant_Name?.split(' ')[1],
                    Company: x.Booking_Participant_Organisation,
                    AttendeeType: x.Booking_Participant_Type,
                    Phone: '',
                    Visit_Id: '',
                    IsVisitor: x.Booking_Visitor,
                    Booking_Resource_Id: null
                }))), 
                Booking_Parties_Visitors: JSON.stringify(booking.Booking_Parties?.filter(x => x.Booking_Participant_Type == 2).map(x => (
                {
                    Email: x.Booking_Participant_Email,
                    First_Name: x.Booking_Participant_Name?.split(' ')[0],
                    Last_Name: x.Booking_Participant_Name?.split(' ')[1],
                    Company: x.Booking_Participant_Organisation,
                    AttendeeType: x.Booking_Participant_Type,
                    Phone: '',
                    Visit_Id: '',
                    IsVisitor: x.Booking_Visitor,
                    Booking_Resource_Id: null
                }))), 
                Cost_Code_Allocation: JSON.stringify(booking.Cost_Code_Allocation),
            };
            return this.api.bookings.createV1Booking(nodeId, v1Booking, suppressErrorPopup ?? false);
        }
    }

    public update(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateBookingResponse>
    {
        if(this.hasV2Rights)
        {
            return this.api.bookings.updateV2Booking(nodeId, bookingId, booking); 
        }
        else
        {
            const v1Booking: IUpdateV1BookingRequest = 
            {
                ...booking,
                Booking_Parties: JSON.stringify(booking.Booking_Parties?.filter(x => x.Booking_Participant_Type == 1).map(x => (
                {
                    Email: x.Booking_Participant_Email,
                    First_Name: x.Booking_Participant_Name?.split(' ')[0],
                    Last_Name: x.Booking_Participant_Name?.split(' ')[1],
                    Company: x.Booking_Participant_Organisation,
                    AttendeeType: x.Booking_Participant_Type,
                    Phone: '',
                    Visit_Id: '',
                    IsVisitor: x.Booking_Visitor,
                    Booking_Resource_Id: null
                }))), 
                Booking_Parties_Visitors: JSON.stringify(booking.Booking_Parties?.filter(x => x.Booking_Participant_Type == 2).map(x =>(
                {
                    Email: x.Booking_Participant_Email,
                    First_Name: x.Booking_Participant_Name?.split(' ')[0],
                    Last_Name: x.Booking_Participant_Name?.split(' ')[1],
                    Company: x.Booking_Participant_Organisation,
                    AttendeeType: x.Booking_Participant_Type,
                    Phone: '',
                    Visit_Id: '',
                    IsVisitor: x.Booking_Visitor,
                    Booking_Resource_Id: null
                }))), 
                Cost_Code_Allocation: JSON.stringify(booking.Cost_Code_Allocation),
            };
            return this.api.bookings.updateV1Booking(nodeId, bookingId, v1Booking);
        }
    }

    public delete(nodeId: number, bookingId: string, suppressErrorPopup?: boolean): Promise<IDeleteBookingResponse>
    {
        if (this.hasV2Rights)
        {
            return this.api.bookings.deleteV2Booking(nodeId, bookingId, suppressErrorPopup ?? false);
        }
        else
        {
            return this.api.bookings.deleteV1Booking(nodeId, bookingId, suppressErrorPopup ?? false);
        }
    }

    public getBookingsForOthers(query: ODataQuery, email: string, start: DateTime, end: DateTime): Promise<PagedResponse<GetMyV2BookingsForOthers.Booking[]>>
    {
        if(!this.hasV2Rights)
        {
            throw new Error("V1 bookings are not supported.");
        }
        return this.api.bookings.getMyV2BookingsForOthers(query, email, start, end);
    }

    public download(buildingId: number, filter: BookingFilter2): Promise<Blob>
    {
        if (this.hasV2Rights)
        {
            return this.api.bookings.downloadV2Booking(buildingId, filter);
        }
        else
        {
            return this.api.bookings.downloadV1Booking(buildingId, filter);
        }
    }
}

export interface IBookingService
{
    get(nodeId: number, bookingId: string): Promise<IGetV2BookingResponse>;
    getBookings(query: ODataQuery, startTime?: DateTime, endTime?: DateTime, spaceIds?: Array<string>): Promise<PagedResponse<GetV2Bookings.Booking[]>>;
    getBookingsForOthers(query: ODataQuery, email: string, start: DateTime, end: DateTime): Promise<PagedResponse<GetMyV2BookingsForOthers.Booking[]>>;
    create(nodeId: number, booking: ICreateV2BookingRequest, suppressErrorPopup?: boolean): Promise<ICreateBookingResponse>;
    update(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateBookingResponse>;
    delete(nodeId: number, bookingId: string, suppressErrorPopup?: boolean): Promise<IDeleteBookingResponse>;
    download(buildingId: number, filter: BookingFilter2): Promise<Blob>;
}
