import { PagedResponse } from "../Models";
import { ICreateDeleteEndpoint } from "./CreateDeleteEndpoint";
import { IGetAllEndpoint } from "./GetAllEndpoint";
import { IGetManyByMenuIdEndpoint } from "./GetManyByMenuIdEndpoint";
import { IGetRoleNamesByCurrentUserEndpoint } from "./GetRoleNamesByCurrentUserEndpoint";
import { IGetRolesByCurrentUserEndpoint } from "./GetRolesByCurrentUserEndpoint";

export class RoleRepository implements IRoleRepository
{
    private getAllEndpoint: IGetAllEndpoint;
    private getManyByMenuIdEndpoint: IGetManyByMenuIdEndpoint;
    private createDeleteEndpoint: ICreateDeleteEndpoint;
    private getRoleNamesByCurrentUserEndpoint: IGetRoleNamesByCurrentUserEndpoint;
    private getRolesByCurrentUserEndpoint: IGetRolesByCurrentUserEndpoint;

    constructor(
        getAllEndpoint: IGetAllEndpoint,
        getManyByMenuIdEndpoint: IGetManyByMenuIdEndpoint,
        createDeleteEndpoint: ICreateDeleteEndpoint,
        getRoleNamesByCurrentUserEndpoint: IGetRoleNamesByCurrentUserEndpoint,
        getRolesByCurrentUserEndpoint: IGetRolesByCurrentUserEndpoint
    )
    {
        this.getAllEndpoint = getAllEndpoint;
        this.getManyByMenuIdEndpoint = getManyByMenuIdEndpoint;
        this.createDeleteEndpoint = createDeleteEndpoint;
        this.getRoleNamesByCurrentUserEndpoint = getRoleNamesByCurrentUserEndpoint;
        this.getRolesByCurrentUserEndpoint = getRolesByCurrentUserEndpoint;
    }

    public async getAll(suppressErrorPopup?: boolean): Promise<DataEntryRole[]>
    {
        return this.getAllEndpoint.execute(suppressErrorPopup == true);
    }

    public getManyByMenuId<TResponse>(nodeId: number, menuId: string, responseType: new () => TResponse): Promise<PagedResponse<TResponse[]>>
    {
        return this.getManyByMenuIdEndpoint.execute(nodeId, menuId, responseType);
    }

    public createDelete(nodeId: number, menuId: string, itemIdsToCreate: string[], itemIdsToDelete: string[]): Promise<void>
    {
        return this.createDeleteEndpoint.execute(nodeId, menuId, itemIdsToCreate, itemIdsToDelete);
    }

    public getRoleNamesByCurrentUser(): Promise<string[]>
    {
        return this.getRoleNamesByCurrentUserEndpoint.execute();
    }

    public getRolesByCurrentUser(): Promise<IRole[]>
    {
        return this.getRolesByCurrentUserEndpoint.execute();
    }

}

export interface IRoleRepository
{
    getAll(suppressErrorPopup?: boolean): Promise<DataEntryRole[]>;
    getManyByMenuId<TResponse>(nodeId: number, menuId: string, responseType: new () => TResponse): Promise<PagedResponse<TResponse[]>>;
    createDelete(nodeId: number, menuId: string, itemIdsToCreate: string[], itemIdsToDelete: string[]): Promise<void>;
    getRoleNamesByCurrentUser(): Promise<string[]>;
    getRolesByCurrentUser(): Promise<IRole[]>;
}

export class DataEntryRole
{
    public id = "";
    public name = "";
}

export class Role
{
    public Id = "";
    public Name = "";
}

export interface IRole
{
    id: string;
    name: string;
    groupId: string;
}
