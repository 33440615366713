import React, { Component } from 'react';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssUserPrefNotificationUI from './IbssUserPrefNotificationUI';
import { appContext } from '../../../../AppContext';
import { PageHelper } from './PageHelper';

class VisitorNotificationState
{
    public visitorNotification = PageHelper.getUserPrefrenceJson();
    public visitorRoamer = false;
    public visitorPush = false;
    public visitorEmail = false;
    public isLoading = false;
};

class VisitorNotification extends Component<any, VisitorNotificationState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider

    constructor(props: any)
    {
        super(props)
        this.state = new VisitorNotificationState();
    };

    componentDidMount()
    {
        this.setState({ isLoading: true })
        const response = this.local.getUserPreferences();
        this.setState({ isLoading: false, visitorNotification: response });
        this.makeData(response);
    };

    public makeData = (response: any) =>
    {
        //convert number to binary
        let visitors = Number(response?.NotificationPrefs?.Visitors && response?.NotificationPrefs?.Visitors);
        this.setState({
            visitorRoamer: visitors !== 0 ? true : false,
            visitorEmail: (visitors === 3 || visitors === 7) ? true : false,
            visitorPush: (visitors === 5 || visitors === 7) ? true : false,
        })
    };

    public change = async (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        if (e.target.name === "visitorRoamer")
        {
            await this.setState({ visitorRoamer: e.target.checked });
        } else if (e.target.name === "visitorEmail")
        {
            await this.setState({ visitorEmail: e.target.checked });
        } else if (e.target.name === "visitorPush")
        {
            await this.setState({ visitorPush: e.target.checked });
        }

        if (this.state.visitorEmail || this.state.visitorPush)
        {
            this.setState({
                visitorRoamer: true
            });
        }
    };

    public getInteger = (roamer: boolean, push: boolean, email: boolean) =>
    {
        let integerValue = 0
        if (push && !email)
        {
            integerValue = 5;
        }
        if (!push && email)
        {
            integerValue = 3;
        }
        if (push && email && roamer)
        {
            integerValue = 7;
        }
        if (roamer && !push && !email)
        {
            integerValue = 1;
        }
        return integerValue
    };

    public submit = () =>
    {
        this.setState({
            visitorNotification: {
                ...this.state.visitorNotification,
                NotificationPrefs: {
                    ...this.state.visitorNotification.NotificationPrefs,
                    Visitors: this.getInteger(this.state.visitorRoamer, this.state.visitorPush, this.state.visitorEmail),
                },
            }
        }, async () =>
        {
            try
            {
                await this.userPreferences.update(this.state.visitorNotification);
                this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
            }
            catch { }
        });
    };

    render()
    {
        const labelsList = [
            {
                label: this.labels.funcInAppNotifications_S,
                checked: this.state.visitorRoamer,
                onChange: (e: any) => this.change(e),
                name: "visitorRoamer"
            },
            {
                label: this.labels.HubLabelPushNotifications,
                checked: this.state.visitorPush,
                onChange: (e: any) => this.change(e),
                name: "visitorPush",
            },
            {
                label: this.labels.HubLabelEmailNotifications,
                checked: this.state.visitorEmail,
                onChange: (e: any) => this.change(e),
                name: "visitorEmail"
            },

        ];
        return (
            <>
                <IbssUserPrefNotificationUI labelsList={labelsList} />

                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => { this.submit() }}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>

            </>
        )
    }
}

export default VisitorNotification;
