import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class Building extends ODataSelect
{
        ID = 0;
        Name = ""
        IsEnabled = 0;
        SortOrder = 0;
        Level = ""
        TimeZone = ""
        TimeZoneDST = 0;
        TimeZoneType = ""
        Agents = {
            Owner: "",
            Operator: "",
            Occupier: ""
        };
        Location = {
            Address: "",
            City: "",
            Country: "",
            Longitude: 0,
            Latitude: 0,
        };
        Physical_Properties = {
            Capacity: "",
            Size: "",
        };
        Node_Search_Config = { config: "" }
        MapURI = ""
        ImageURI = ""
}
        