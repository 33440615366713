import { AccordionSummary, Typography, AccordionDetails, Accordion } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Component } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

class IbssAccordion extends Component<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            expandedKey: '',
        };
    }

    private handleAccordionChange(key: string): void 
    {
        key == this.state.expandedKey ? this.setState({ expandedKey: '' }) : this.setState({ expandedKey: key });

        if (this.props?.expandMoreClickedHandler)
        {
            this.props.expandMoreClickedHandler();
        }
    };

    public render(): JSX.Element 
    {
        const ShowLessIcon = this.props?.collapseIcon ?? ExpandLessIcon;
        const ShowMoreIcon = this.props?.expandMoreIcon ?? ExpandMoreIcon;

        return (
            <div>
                {
                    this.props.values.map((x) =>
                    {
                        const isExpanded = this.props?.isExpanded ?? this.state.expandedKey === x.key;
                        return (
                            <Accordion
                                key={x.key}
                                sx={{
                                    marginTop: this.props.spacing ?? 'unset',
                                    borderWidth: this.props.borderWidth ?? 'unset',
                                }}
                                expanded={isExpanded}
                                variant={this.props?.variant ?? 'elevation'}
                                onClick={() => this.props.onlyExpandOnIconClick ? {} : this.handleAccordionChange(x.key)}
                            >
                                {
                                    !x.hideSummary &&
                                    <AccordionSummary
                                        expandIcon={x.hideExpand != undefined && x.hideExpand == true ? <div style={{ width: '24px' }} /> : (isExpanded ? <ShowLessIcon onClick={() => this.handleAccordionChange(x.key)} /> : <ShowMoreIcon onClick={() => this.handleAccordionChange(x.key)} />)}
                                        aria-controls={`panel${x.key}-content`}
                                        id={x.key}
                                        onClick={() => this.handleAccordionChange(x.key)}
                                    >
                                        {x.summary}
                                    </AccordionSummary>
                                }
                                <AccordionDetails>
                                    {x.details}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
            </div>
        )
    }
}

export default IbssAccordion;

export interface IProps
{
    values: IAccordion[];
    spacing?: string;
    borderWidth?: string;
    variant?: "elevation" | "outlined";
    expandMoreIcon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    collapseIcon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    isExpanded?: boolean;
    expandMoreClickedHandler?: () => void;
    onlyExpandOnIconClick?: boolean
    expandOnSummaryClick?: boolean;
}

export interface IState
{
    expandedKey: string;
}

export interface IAccordion 
{
    key: string;
    summary: React.ReactNode;
    details: React.ReactNode;
    hideExpand?: boolean;
    hideSummary?: boolean;
}