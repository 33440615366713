import { Card, Grid } from '@mui/material';
import { IbssPage } from '../../../../Components/Core/BasePage/IbssPage';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { appContext } from '../../../../AppContext';
import BuildingOverviewForm from './BuildingOverviewForm';
import IbssAccordion from '../../../../Components/Miscellaneous/Accordion/IbssAccordion';
import BuildingLocationForm from './BuildingLocationForm';
import BuildingDetailsForm from './BuildingDetailsForm';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { RouteComponentProps } from 'react-router-dom';
import Helper from '../../../../Common/Helper';
import { Building } from './DataModels';

class EditBuilding extends IbssPage<IProps, IState>
{

    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            //Overview
            buildingOwner: '',
            buildingOperator: '',
            buildingId: '',
            buildingName: '',
            buildingOccupier: '',
            buildingSize: '',
            buildingCapacity: '',
            buildingLongitude: '',
            buildingLatitude: '',
            activeSpace: false,

            //Location
            address: '',
            city: '',
            country: '',
            searchConfigurationJson: '{}',

            //Details
            timeZone: '',
            timeZoneDst: false,
            imageUrl: '',
            sortName: '',

            images: [],
            loading: false
        };
    };

    public async componentDidMount(): Promise<void>
    {
        this.pageTitle = this.labels.funcBuildingSetup_S;
        if (this.props.match.params.buildingId)
        {
            await this.getBuildingData();
        }
        this.getImages();
    }

    private async getBuildingData(): Promise<void>
    {
        this.setState({ loading: true });
        try
        {
            const buildingData = await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.get<Building>({ nodeId: parseInt(this.props.match.params.buildingId), select: Building })

            this.setState({
                //Overview
                buildingOwner: buildingData.Agents.Owner ?? "",
                buildingOperator: buildingData.Agents.Operator ?? "",
                buildingId: buildingData.ID.toString(),
                buildingName: buildingData.Name,
                buildingOccupier: buildingData.Agents.Occupier ?? "",
                buildingSize: buildingData.Physical_Properties.Size ?? "",
                buildingCapacity: buildingData.Physical_Properties.Capacity ?? "",
                buildingLongitude: buildingData.Location.Longitude?.toString() ?? "",
                buildingLatitude: buildingData.Location.Latitude?.toString() ?? "",
                activeSpace: buildingData.IsEnabled == 1 ? true : false,

                //Location
                address: buildingData.Location.Address ?? "",
                city: buildingData.Location.City ?? "",
                country: buildingData.Location.Country ?? "",
                searchConfigurationJson: buildingData.Node_Search_Config.config,

                //Details
                timeZone: buildingData.TimeZone,
                timeZoneDst: buildingData.TimeZoneDST == 1 ? true : false,
                imageUrl: buildingData.ImageURI,
                sortName: buildingData.SortOrder?.toString(),

                loading: false
            });
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private async getImages(): Promise<void> 
    {
        try 
        {
            const files = await appContext().webEntryApiClientV1.api.webentry.v1.file.byPath.get<IFile[]>({
                path: 'c/Buildings'
            })
            const filesWithPath = files.map((item: IFile) =>
            {
                let newItem = { ...item }
                newItem.path = `https://storage.ibss.${Helper.environment}/images/c/Buildings/${item.name}`
                return newItem
            })
            this.setState({ images: filesWithPath });
        }
        catch (error) 
        {

        }
    }

    private async uploadImage(filename: string, file: FormData, processAsMap: boolean): Promise<void>
    {
        try 
        {
            await appContext().webEntryApiClientV1.api.webentry.v1.file.byPath.post({
                path: 'c/Buildings/' + filename,
                processAsMapFile: false,
                body: file
            });
            this.getImages();
        }
        catch 
        {

        }
    }

    private save(): void
    {
        if (this.props.match.params.buildingId)
        {
            this.updateBuilding();
        }
        else
        {
            this.createBuilding();
        }
    }

    private async updateBuilding(): Promise<void>
    {
        this.setState({ loading: true });
        try
        {
            await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeId.put({
                nodeId: parseInt(this.props.match.params.buildingId),
                body: {
                    ID: parseInt(this.props.match.params.buildingId),
                    Name: this.state.buildingName,
                    Deleted: false,
                    CustomNodeID: null,
                    DisplayName: this.state.buildingName,
                    PartitionKey: null,
                    Parent_ID: 1,
                    IsEnabled: this.state.activeSpace ? 1 : 0,
                    SortOrder: this.state.sortName,
                    Level: "Building",
                    TimeZone: this.state.timeZone,
                    TimeZoneType: "",
                    TimeZoneDST: this.state.timeZoneDst ? 1 : 0,
                    Agents: {
                        Owner: this.state.buildingOwner,
                        Operator: this.state.buildingOperator,
                        Occupier: this.state.buildingOccupier
                    },
                    Physical_Properties: {
                        Capacity: this.state.buildingCapacity,
                        Size: this.state.buildingSize
                    },
                    Location: {
                        Address: this.state.address,
                        City: this.state.city,
                        Country: this.state.country,
                        Longitude: this.state.buildingLongitude,
                        Latitude: this.state.buildingLatitude
                    },
                    Node_Search_Config: { config: this.state.searchConfigurationJson },
                    MapURI: "",
                    ImageURI: this.state.imageUrl,
                }
            });
            this.props.history.push('/admin/buildings')
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    private async createBuilding(): Promise<void>
    {
        this.setState({ loading: true });
        try
        {
            await appContext().ibssApiClientV2.v2.configuration.nodes.post({
                body: {
                    Name: this.state.buildingName,
                    Deleted: false,
                    CustomNodeID: null,
                    DisplayName: this.state.buildingName,
                    PartitionKey: null,
                    Parent_ID: 1,
                    IsEnabled: this.state.activeSpace ? 1 : 0,
                    SortOrder: this.state.sortName,
                    Level: "Building",
                    TimeZone: this.state.timeZone,
                    TimeZoneType: "",
                    TimeZoneDST: this.state.timeZoneDst ? 1 : 0,
                    Agents: {
                        Owner: this.state.buildingOwner,
                        Operator: this.state.buildingOperator,
                        Occupier: this.state.buildingOccupier
                    },
                    Physical_Properties: {
                        Capacity: this.state.buildingCapacity,
                        Size: this.state.buildingSize
                    },
                    Location: {
                        Address: this.state.address,
                        City: this.state.city,
                        Country: this.state.country,
                        Longitude: this.state.buildingLongitude,
                        Latitude: this.state.buildingLatitude
                    },
                    Node_Search_Config: { config: this.state.searchConfigurationJson },
                    MapURI: "",
                    ImageURI: this.state.imageUrl,
                }
            });
            this.props.history.push('/admin/buildings')
        } catch (error)
        {
            this.setState({ loading: false });
        }
    }

    public render(): JSX.Element
    {
        return (
            <>
                {
                    this.state.loading &&
                    <LoadingOverlay />
                }
                <div className="rightPanel-main-content m-3">
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <Card>
                                <div className='m-4'>
                                    <div className='d-flex mb-3'>
                                        <div className="mr-2" style={{ background: 'var(--ui-mid-tone)', borderRadius: '10px', padding: '5px 5px 2px 5px' }}>
                                            <IbssSvgIcon fontSize='large'>
                                                {Icons.BuildingOverview}
                                            </IbssSvgIcon>
                                        </div>
                                        <div style={{ paddingTop: '4px', alignContent: 'center' }}>
                                            {this.labels.funcBuildingOverview_S}
                                        </div>
                                    </div>
                                    <BuildingOverviewForm
                                        owner={this.state.buildingOwner}
                                        ownerChanged={e => this.setState({ buildingOwner: e })}
                                        operator={this.state.buildingOperator}
                                        operatorChanged={e => this.setState({ buildingOperator: e })}
                                        id={this.state.buildingId}
                                        name={this.state.buildingName}
                                        nameChanged={e => this.setState({ buildingName: e })}
                                        occupier={this.state.buildingOccupier}
                                        occupierChanged={e => this.setState({ buildingOccupier: e })}
                                        size={this.state.buildingSize}
                                        sizeChanged={e => this.setState({ buildingSize: e })}
                                        capacity={this.state.buildingCapacity}
                                        capacityChanged={e => this.setState({ buildingCapacity: e })}
                                        longitude={this.state.buildingLongitude}
                                        longitudeChanged={e => this.setState({ buildingLongitude: e })}
                                        latitude={this.state.buildingLatitude}
                                        latitudeChanged={e => this.setState({ buildingLatitude: e })}
                                        activeSpace={this.state.activeSpace}
                                        activeSpaceChanged={e => this.setState({ activeSpace: e })}
                                    />
                                </div>
                            </Card>
                        </Grid>
                        <Grid item sm={6}>
                            <IbssAccordion
                                onlyExpandOnIconClick
                                expandOnSummaryClick
                                values={[{
                                    key: 'summary-accordion',
                                    summary: (
                                        <div className='d-flex' style={{ padding: '10px' }}>
                                            <div className="mr-2" style={{ background: 'var(--ui-mid-tone)', borderRadius: '10px', padding: '9px' }}>
                                                <IbssSvgIcon fontSize='medium'>
                                                    {Icons.LocationIcon}
                                                </IbssSvgIcon>
                                            </div>
                                            <div style={{ paddingTop: '4px', alignContent: 'center' }}>
                                                {this.labels.funcBuildingLocation_S}
                                            </div>
                                        </div>
                                    ),
                                    details: (
                                        <BuildingLocationForm
                                            address={this.state.address}
                                            addressChanged={e => this.setState({ address: e })}
                                            city={this.state.city}
                                            cityChanged={e => this.setState({ city: e })}
                                            country={this.state.country}
                                            countryChanged={e => this.setState({ country: e })}
                                        />
                                    )
                                }]} />
                            <div className='mt-1'>
                                <IbssAccordion
                                    onlyExpandOnIconClick
                                    expandOnSummaryClick
                                    values={[{
                                        key: 'summary-accordion',
                                        summary: (
                                            <div className='d-flex' style={{ padding: '10px' }}>
                                                <div className="mr-2" style={{ background: 'var(--ui-mid-tone)', borderRadius: '10px', padding: '9px' }}>
                                                    <IbssSvgIcon fontSize='medium'>
                                                        {Icons.SettingIcon}
                                                    </IbssSvgIcon>
                                                </div>
                                                <div style={{ paddingTop: '4px', alignContent: 'center' }}>
                                                    {this.labels.funcBuildingDetails_S}
                                                </div>
                                            </div>
                                        ),
                                        details: (
                                            <BuildingDetailsForm
                                                images={this.state.images.map(i => ({ name: i.name, url: i.path }))}
                                                timeZone={this.state.timeZone}
                                                timeZoneChanged={(value) => this.setState({ timeZone: value })}
                                                timeZoneDst={this.state.timeZoneDst}
                                                timeZoneDstChanged={(value) => this.setState({ timeZoneDst: value })}
                                                uploadImage={(filename: string, file: FormData, processAsMap: boolean) => this.uploadImage(filename, file, processAsMap)}
                                                imageUrl={this.state.imageUrl}
                                                imageChanged={(file) => this.setState({ imageUrl: file })}
                                                onSearchConfigurationSubmitted={(json: string) => this.setState({ searchConfigurationJson: json })}
                                                searchConfigurationJson={this.state.searchConfigurationJson}
                                                sortName={this.state.sortName}
                                                sortNameChanged={(value) => this.setState({ sortName: value })}
                                                newBuilding={this.props.match.params.buildingId == undefined}
                                            />
                                        )
                                    }]} />
                            </div>
                        </Grid>
                    </Grid>
                    <div className='mt-2' style={{ textAlignLast: 'right', width: '100%' }}>
                        <IbssButton className='mr-2' variant='contained' color='secondary' onClick={() => this.props.history.push('/admin/buildings')}>{this.labels.HubButtonCancel}</IbssButton>
                        <IbssButton disabled={this.state.buildingName == ''} variant='contained' color='primary' onClick={() => this.save()}>{this.props.match.params.buildingId ? this.labels.HubLabelUpdate : this.labels.HubButtonSave}</IbssButton>
                    </div>
                </div>
            </>
        )
    }
}

export default EditBuilding;

export interface IProps extends RouteComponentProps<IMatchParams>
{
}

export interface IState
{
    buildingOwner: string;
    buildingOperator: string;
    buildingId: string;
    buildingName: string;
    buildingOccupier: string;
    buildingSize: string;
    buildingCapacity: string;
    buildingLongitude: string;
    buildingLatitude: string;
    activeSpace: boolean;

    address: string;
    city: string;
    country: string;

    timeZone: string;
    timeZoneDst: boolean;
    imageUrl: string;
    searchConfigurationJson: string;
    sortName: string;

    images: IFile[];
    loading: boolean;
}

export interface IFile
{
    name: string;
    path: string;
    lastModified: string;
    createdAt: string;
    size: string;
}

export interface IMatchParams
{
    buildingId: string;
}