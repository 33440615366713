import React from 'react';
import
{
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import IbssDrawer from '../../../../Components/Drawer/IbssDrawer';
import { appContext } from '../../../../AppContext';
import { Floor } from '../List/DataModel';
import FloorMapDialog from './FloorMapDialog';
import Helper from '../../../../Common/Helper';
import FloorOverviewForm from './FloorOverviewForm';
import IbssDialog from '../../../../Components/Dialogs/BaseDialog/IbssDialog';

class EditFloor extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoading: false,
            zones: [],
            selectedZones: [],
            selectedId: 0,
            displayName: '',
            displayOrder: '',
            floorId: '',
            occupier: '',
            operator: '',
            owner: '',
            capacity: '',
            floorSize: '',
            openFloorMapDialog: false,
            floorMapUrl: '',
            floorImage: null,
            uploadMapView: false,
            viewMapView: false,
            images: [],
            mapUri: '',
            showSearchConfigurationPopup: false,
            searchConfigurationJson: '{}',
            showSearchConfigurationError: false,
            isAddZoneEnabled: false,
            originalFloorData: new Floor(),
            originalZones: [],
        };
    }

    public componentDidMount(): void
    {
        this.getImages();
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevProps.selectedFloorId !== this.props.selectedFloorId)
        {
            if (this.props.selectedFloorId === 0)
            {
                await this.getInitalState();
                this.setState({ isAddZoneEnabled: false });
                this.storeOriginalData({} as Floor, []);
            } 
            else
            {
                await this.loadFloorAndZones();
                this.getImages();
                this.setState({ isAddZoneEnabled: true });
            }
        }
    };

    private async fetchZones(): Promise<void>
    {
        try
        {
            const zonesResponse = await appContext().ibssApiClientV2.v2.configuration.nodes.get<Floor[]>({
                filter: `Parent_ID eq ${this.props.selectedFloorId} and Level eq 'Zone'`,
                select: Floor,
            });

            if (zonesResponse)
            {
                const zones = zonesResponse.map(zone => ({
                    id: zone.ID.toString(),
                    name: zone.Name,
                    displayName: zone.DisplayName,
                    capacity: zone.Physical_Properties?.Capacity || '',
                    isSelected: false
                }));
                this.setState({
                    zones,
                    originalZones: JSON.parse(JSON.stringify(zones)),
                    selectedZones: []
                });
            }
        } catch (error)
        {
        }
    };

    private getFloorData(): Floor
    {
        const floor = new Floor();
        floor.Name = this.state.displayName;
        floor.DisplayName = this.state.floorId;
        floor.SortOrder = this.state.displayOrder;
        floor.Agents = 
        {
            Occupier: this.state.occupier,
            Operator: this.state.operator,
            Owner: this.state.owner
        };
        floor.Physical_Properties = 
        {
            Capacity: this.state.capacity,
            Size: this.state.floorSize
        };
        floor.MapURI = this.state.mapUri;
        floor.Node_Search_Config = 
        {
            config: this.state.searchConfigurationJson
        };
        return floor;
    }

    private hasFloorDataChanged(): boolean
    {
        const currentData = this.getFloorData();
        return Object.keys(currentData).some(key =>
            currentData[key as keyof Floor] !== this.state.originalFloorData[key as keyof Floor]
        );
    }

    private haveZonesChanged(): boolean
    {
        if (this.state.zones.length !== this.state.originalZones.length) return true;

        return this.state.zones.some((zone, index) =>
        {
            const originalZone = this.state.originalZones[index];
            return zone.name !== originalZone.name ||
                zone.displayName !== originalZone.displayName ||
                zone.capacity !== originalZone.capacity;
        });
    }

    private storeOriginalData(floorResponse?: Floor, zones?: Zone[]): void
    {
        if (floorResponse)
        {
            this.setState({ originalFloorData: floorResponse });
        }
        if (zones)
        {
            this.setState({ originalZones: JSON.parse(JSON.stringify(zones)) });
        }
    }

    private async loadFloorAndZones(): Promise<void>
    {
        const floorResponse = await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.get<Floor>({
            nodeId: this.props.selectedFloorId,
            select: Floor
        });

        if (floorResponse)
        {
            const newState = {
                selectedId: floorResponse.ID,
                displayName: floorResponse.Name,
                floorId: floorResponse.DisplayName,
                displayOrder: floorResponse.SortOrder,
                occupier: floorResponse.Agents.Occupier,
                operator: floorResponse.Agents.Operator,
                owner: floorResponse.Agents.Owner,
                capacity: floorResponse.Physical_Properties.Capacity,
                floorSize: floorResponse.Physical_Properties.Size,
                searchConfigurationJson: floorResponse.Node_Search_Config.config,
                mapUri: floorResponse.MapURI,
            };
            this.setState(newState);

            const zonesResponse = await appContext().ibssApiClientV2.v2.configuration.nodes.get<Floor[]>({
                filter: `Parent_ID eq ${this.props.selectedFloorId} and Level eq 'Zone'`,
                select: Floor,
            });

            if (zonesResponse)
            {
                const zones = zonesResponse.map(zone => ({
                    id: zone.ID.toString(),
                    name: zone.Name,
                    displayName: zone.DisplayName,
                    capacity: zone.Physical_Properties?.Capacity || '',
                    isSelected: false
                }));
                this.setState({ zones });
                this.storeOriginalData(floorResponse, zones);
            }
        }
    }

    private async saveFloorData(): Promise<void>
    {
        const floorData = 
        {
            Name: this.state.displayName,
            Deleted: false,
            CustomNodeID: null,
            DisplayName: this.state.floorId,
            PartitionKey: null,
            Parent_ID: this.props.buildingId,
            IsEnabled: 0,
            SortOrder: this.state.displayOrder,
            TimeZoneDST: 0,
            Level: "Floor",
            MapURI: this.state.mapUri,
            ImageURI: "",
            Node_Search_Config: {
                config: this.state.searchConfigurationJson
            },
            Agents:
            {
                Owner: this.state.owner,
                Operator: this.state.operator,
                Occupier: this.state.occupier,
            },
            Physical_Properties:
            {
                Capacity: this.state.capacity,
                Size: this.state.floorSize
            },
        }

        if (this.props.selectedFloorId === 0)
        {
            const createFloorResponse: Floor = await appContext().ibssApiClientV2.v2.configuration.nodes.post({
                body: floorData
            });
            this.props.selectedIdChanged(createFloorResponse.ID);
            this.setState({ isAddZoneEnabled: true });
        }
        else
        {
            await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.patch({
                nodeId: this.state.selectedId,
                body: floorData
            });
        }
    }

    private async getInitalState(): Promise<void>
    {
        this.setState({
            displayName: "",
            displayOrder: "",
            floorId: "",
            occupier: "",
            operator: "",
            owner: "",
            capacity: "",
            floorSize: "",
            mapUri: "",
            zones: [],
        });
    }

    private async saveFloorUpdate(): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });
            const floorChanged = this.hasFloorDataChanged();
            const zonesChanged = this.haveZonesChanged();

            if (floorChanged)
            {
                await this.saveFloorData();
            }

            if (zonesChanged)
            {
                await this.saveAllZones();
            }

            if (floorChanged || zonesChanged)
            {
                await this.props.loadFloorDetails();
                if (floorChanged)
                {
                    this.setState({ originalFloorData: this.getFloorData() });
                }
            }
        } catch (error)
        {
            this.setState({ isLoading: false });
        } finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async handleSaveZonesOnly(): Promise<void>
    {
        if (this.haveZonesChanged())
        {
            await this.saveAllZones();
            await this.props.loadFloorDetails();
        }
    }


    private async saveAllZones(): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });

            for (const zone of this.state.zones)
            {
                const zoneData = {
                    Name: zone.name,
                    Deleted: false,
                    CustomNodeID: null,
                    DisplayName: zone.displayName,
                    PartitionKey: null,
                    Parent_ID: this.props.selectedFloorId,
                    IsEnabled: 1,
                    SortOrder: this.state.displayOrder,
                    Level: "Zone",
                    TimeZoneDST: 0,
                    MapURI: "",
                    ImageURI: "",
                    Physical_Properties: {
                        Capacity: zone.capacity,
                    },
                    Node_Search_Config: {
                        config: ""
                    },
                };

                if (zone.id.startsWith('new-'))
                {
                    await appContext().ibssApiClientV2.v2.configuration.nodes.post({
                        body: zoneData
                    });
                } else
                {
                    await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.patch({
                        nodeId: parseInt(zone.id),
                        body: zoneData
                    });
                }
            }

            await this.fetchZones();

        } catch (error)
        {
            this.setState({ isLoading: false });
        } finally
        {
            this.setState({ isLoading: false });
        }
    }

    public handleAddZone(): void
    {
        const newZone = {
            id: `new-${Date.now()}`,
            name: '',
            displayName: '',
            capacity: '',
            isSelected: false
        };
        this.setState({
            zones: [...this.state.zones, newZone]
        });
    }

    public async handleDeleteZone(): Promise<void> 
    {
        try 
        {
            this.setState({ isLoading: true });
           
            // Separate selected zones into existing and new
            const existingZoneIds = this.state.selectedZones.filter(id => !id.startsWith('new-'));
            const newZoneIds = this.state.selectedZones.filter(id => id.startsWith('new-'));
           
            if (existingZoneIds.length > 0) 
            {
                for (const zoneId of existingZoneIds) 
                {
                    try 
                    {
                        await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.delete({
                            nodeId: parseInt(zoneId),
                        });
                    } 
                    catch (error) 
                    {
                    }
                }
 
                // After fetching, we need to preserve any remaining new zones
                const currentNewZones = this.state.zones.filter(zone =>
                    zone.id.startsWith('new-') && !newZoneIds.includes(zone.id)
                );
               
                // Fetch updated zones from server
                await this.fetchZones();
               
                this.setState(prevState => ({
                    zones: [...prevState.zones, ...currentNewZones]
                }));
            }
           
            // Handle new zones locally
            if (newZoneIds.length > 0) 
            {
                const updatedZones = this.state.zones.filter(zone =>
                    !newZoneIds.includes(zone.id)
                );
                this.setState({ zones: updatedZones });
            }
           
            this.setState({ selectedZones: [] });
        } 
        finally 
        {
            this.setState({ isLoading: false });
        }
    }
 

    public async handleDisableZone(): Promise<void>
    {
        try
        {
            this.setState({ isLoading: true });
            for (const zoneId of this.state.selectedZones)
            {
                if (!zoneId.startsWith('new-'))
                { // Skip newly created zones
                    try
                    {
                        await appContext().ibssApiClientV2.v2.configuration.nodes.byNodeid.disable.patch({
                            nodeId: parseInt(zoneId),
                        });
                    } catch (error)
                    {
                        this.setState({ isLoading: false });
                    }
                }
            }
            await this.fetchZones();
            this.setState({ selectedZones: [] });
        } finally
        {
            this.setState({ isLoading: false });
        }
    }


    public handleZoneChange(id: string, field: keyof Zone, value: string): void {
        const updatedZones = this.state.zones.map(zone =>
            zone.id === id ? { ...zone, [field]: value } : zone
        );
        this.setState({
            zones: updatedZones
        });
    }
     
    public handleZoneSelect(id: string, isSelected: boolean): void
    {
        const updatedZones = this.state.zones.map(zone =>
            zone.id === id ? { ...zone, isSelected } : zone
        );
        const updatedSelectedZones = isSelected
            ? [...this.state.selectedZones, id]
            : this.state.selectedZones.filter(zoneId => zoneId !== id);
        this.setState({
            zones: updatedZones,
            selectedZones: updatedSelectedZones,
        });
    }

    private async getImages(): Promise<void> 
    {
        try 
        {
            const files = await appContext().webEntryApiClientV1.api.webentry.v1.file.byPath.get<IFile[]>({
                path: 'r/Maps'
            })
            const filesWithPath = files.map((item: IFile) =>
            {
                let newItem = { ...item }
                newItem.path = `https://storage.ibss.${Helper.environment}/images/r/Maps/${item.name}`
                return newItem
            })

            this.setState({ images: filesWithPath });
        }
        catch (error) 
        {
        }
    }

    private imagePreview(img: string): void
    {
        this.setState({
            mapUri: img
        })
    }

    private get searchConfigurationIsValid(): boolean
    {
        if (this.state.showSearchConfigurationError)
        {
            return false;
        }
        try
        {
            const object = JSON.parse(this.state.searchConfigurationJson);
            return !Array.isArray(object);
        }
        catch
        {
            return false;
        }
    }

    private prettify(): void
    {
        try
        {
            this.setState({ searchConfigurationJson: JSON.stringify(JSON.parse(this.state.searchConfigurationJson), null, 4) });
        }
        catch
        {
        }
    }


    private async searchConfigurationSubmitted(): Promise<void>
    {
        this.setState({ showSearchConfigurationPopup: false, showSearchConfigurationError: false });
    }

    public render(): JSX.Element
    {
        const canDisable = this.state.selectedZones.some(zoneId => !zoneId.startsWith('new-'));
        const zonesSelected = this.state.selectedZones.length > 0;

        return (
            <IbssDrawer open={this.props.open} onClose={this.props.onClose} anchor='right' className='flex-search-filter-criteria' sx={{ backgroundColor: "var(--ui-background-alternate)" }}>
                <Box sx={{ p: 2 }}>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">{this.labels.funcFloorOverview_S}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FloorOverviewForm
                                displayName={this.state.displayName}
                                displayNameChanged={(e) => this.setState({ displayName: e })}
                                floorId={this.state.floorId}
                                floorIdChanged={(e) => this.setState({ floorId: e })}
                                displayOrder={this.state.displayOrder}
                                displayOrderChanged={(e) => this.setState({ displayOrder: e })}
                                occupier={this.state.occupier}
                                occupierChanged={(e) => this.setState({ occupier: e })}
                                operator={this.state.operator}
                                operatorChanged={(e) => this.setState({ operator: e })}
                                owner={this.state.owner}
                                ownerChanged={(e) => this.setState({ owner: e })}
                                capacity={this.state.capacity}
                                capacityChanged={(e) => this.setState({ capacity: e })}
                                floorSize={this.state.floorSize}
                                floorSizeChanged={(e) => this.setState({ floorSize: e })}
                                mapUri={this.state.mapUri}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <IbssButton variant="contained" color="primary" onClick={() => this.setState({ openFloorMapDialog: true, uploadMapView: true, viewMapView: false })}>
                                    {this.labels.funcUploadMap_S}
                                </IbssButton>
                                <IbssButton variant="contained" color="secondary" onClick={() => this.setState({ openFloorMapDialog: true, uploadMapView: false, viewMapView: true })}>
                                    {this.labels.funcSpaceLayoutViewMap_S}
                                </IbssButton>
                            </Box>
                            <FloorMapDialog
                                images={this.state.images}
                                selectedImageUrl={this.state.mapUri}
                                open={this.state.openFloorMapDialog}
                                onClose={() => this.setState({ openFloorMapDialog: false })}
                                mode={this.state.uploadMapView ? 'upload' : 'view'}
                                imagePreview={(img: string) => this.imagePreview(img)}
                                getImages={() => this.getImages()}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Typography variant="h6" sx={{ mt: 2 }}>{this.labels.funcFloorZones_S}</Typography>
                    <IbssButton variant="contained" color="primary" fullWidth sx={{ mt: 2, mb: 2 }} onClick={() => this.setState({ showSearchConfigurationPopup: true })}>
                        {this.labels.funcViewUpdates_S}
                    </IbssButton>

                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">{this.labels.HubLabelZones}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                                 <IbssButton
                                    startIcon={<DeleteIcon />}
                                    onClick={() => this.handleDeleteZone()}
                                    disabled={!zonesSelected || this.state.isLoading}
                                >
                                    {this.labels.HubButtonDelete}
                                </IbssButton>
                                <IbssButton
                                    startIcon={<BlockIcon />}
                                    onClick={() => this.handleDisableZone()}
                                    disabled={!canDisable || this.state.isLoading}
                                >
                                    {this.labels.HubLabelDisable}
                                </IbssButton>
                                <IbssButton
                                    startIcon={<AddIcon />}
                                    onClick={() => this.handleAddZone()}
                                    disabled={!this.state.isAddZoneEnabled}
                                >
                                    {this.labels.HubButtonAdd}
                                </IbssButton>
                            </Box>
                            {this.state.zones.map((zone) => (
                                <Accordion key={zone.id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={zone.isSelected}
                                                    onChange={(e) => this.handleZoneSelect(zone.id, e.target.checked)}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                            }
                                            label={zone.name || 'New Zone'}
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <IbssTextField
                                            fullWidth
                                            label={this.labels.HubLabelDisplayName}
                                            value={zone.displayName}
                                            onChange={(e) => this.handleZoneChange(zone.id, 'displayName', e.target.value)}
                                            margin="normal"
                                        />
                                        <IbssTextField
                                            fullWidth
                                            label={this.labels.HubLabelName}
                                            value={zone.name}
                                            onChange={(e) => this.handleZoneChange(zone.id, 'name', e.target.value)}
                                            margin="normal"
                                        />
                                        <IbssTextField
                                            fullWidth
                                            label={this.labels.HubLabelCapacity}
                                            value={zone.capacity}
                                            onChange={(e) => this.handleZoneChange(zone.id, 'capacity', e.target.value)}
                                            margin="normal"
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            <Box sx={{ mt: 2 }}>
                                <IbssButton
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={() => this.handleSaveZonesOnly()}
                                    disabled={!this.state.isAddZoneEnabled || !this.props.isEditMode}
                                >
                                    {this.labels.HubLabelSaveUpdates}
                                </IbssButton>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Box sx={{ p: 2 }}>
                        <IbssButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => this.saveFloorUpdate()}
                        >
                            {this.labels.HubLabelSaveChanges}
                        </IbssButton>
                    </Box>
                </Box>
                <IbssDialog
                    open={this.state.showSearchConfigurationPopup}
                    onClose={() => this.setState({ showSearchConfigurationPopup: false })}
                    fullWidth
                    header={this.labels.funcSearchConfigurationJSONViewer_S}
                    dialogContent=
                    {
                        <>
                            <div className='d-flex justify-content-between' style={{ alignItems: 'center' }}>
                                <label>{this.labels.funcSearchConfigJsonSubheader_S}</label>
                                <IbssButton
                                    color='primary'
                                    disabled={!this.searchConfigurationIsValid}
                                    onClick={() => this.prettify()}
                                >
                                    {this.labels.funcFormatJSON_S}
                                </IbssButton>
                            </div>
                            {
                                !this.searchConfigurationIsValid &&
                                <div className="text-danger">{this.labels.HubLabelInvalidJSONMessage}</div>
                            }
                            <div className="nonePointer" style={{ margin: "10px 0 0 0" }}>
                                <textarea
                                    name="modalJSON"
                                    onChange={e => this.setState({ searchConfigurationJson: e.target.value, showSearchConfigurationError: false })}
                                    className="textAreaUserDetails"
                                    value={this.state.searchConfigurationJson} />
                            </div>
                        </>
                    }
                    footer=
                    {
                        <>
                            <IbssButton
                                color='primary'
                                variant='contained'
                                className='mr-2'
                                disabled={!this.searchConfigurationIsValid}
                                onClick={() => this.searchConfigurationSubmitted()}
                            >
                                {this.labels.HubLabelOk}
                            </IbssButton>
                        </>
                    }
                />
            </IbssDrawer>
        );
    }
}

export default EditFloor;



export interface IFile
{
    name: string;
    path: string;
    lastModified: string;
    createdAt: string;
    size: string;
}


interface Zone
{
    id: string;
    name: string;
    displayName: string;
    capacity: string;
    isSelected: boolean;
}


interface IProps
{
    open: boolean;
    selectedFloorId: number;
    buildingId: number;
    onClose: () => void;
    loadFloorDetails: () => Promise<void>;
    selectedIdChanged: (id: number) => void;
    isEditMode: boolean;
}

interface IState
{
    selectedZones: string[];
    displayName: string;
    selectedId: number;
    displayOrder: string;
    floorId: string;
    occupier: string;
    operator: string;
    owner: string;
    capacity: string;
    floorSize: string;
    openFloorMapDialog: boolean;
    floorMapUrl: string;
    floorImage: File | null;
    uploadMapView: boolean;
    viewMapView: boolean;
    images: IFile[];
    mapUri: string;
    zones: Zone[];
    showSearchConfigurationPopup: boolean;
    searchConfigurationJson: string,
    showSearchConfigurationError: boolean;
    isAddZoneEnabled: boolean;
    originalFloorData: Floor;
    originalZones: Zone[];
    isLoading: boolean;
}