import { Box, SelectChangeEvent } from '@mui/material'
import React from 'react'
import { appContext } from '../../../../AppContext';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import { IFilterOptions, IHostDetail } from './EditVisit';
import { IbssLuxonDateTimePicker } from '../../../../Components/Inputs/LuxonDateTimePicker/IbssLuxonDateTimePicker';
import { DateTime } from 'luxon';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';


class EditHostDetails extends IbssComponent<IProps, IState> 
{
    private get labels() { return appContext().labels; }

    render() {
        return (
            <div>
                <h3>{this.labels.funcHostDetails_S}</h3>
                <IbssTextField 
                    fullWidth
                    required
                    name="hostName"
                    label={this.labels.HubLabelHost}
                    value={this.props.hostDetail.hostName}
                    variant="outlined" 
                    className='mt-3'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>)=> this.props.onHostNameChanged(event)}
                    error={this.props.hostDetail.hostName == "" ? true : false}
                    helperText={this.props.hostDetail.hostName == "" ? this.labels.HubLabelHostNamePlaceholderText : ""}
                />
                <IbssTextField 
                    fullWidth
                    required
                    name="hostEmail"
                    label={this.labels.HubLabelHostNamePlaceholder}
                    value={this.props.hostDetail.hostEmail}
                    variant="outlined" 
                    className='mt-3'
                    disabled
                />
                <Box my={2}>
                    <IbssInputDropDown
                        onChange={e => this.props.onVisitorArrivalLocations(e)}
                        value={this.props.visitorArrivalLocationId}
                        inputLabel={this.labels.HubLabelArrivalLocationId}
                        id="Arrival LocationId"
                        options={this.props.visitorArrivalLocations}
                    />
                </Box>
                <hr />
                <h3>{this.labels.funcTimings_S}</h3>
                <Box display={'flex'} sx={{'& > :not(style)': { my: 1,mr:1},}}>
                    <IbssLuxonDateTimePicker
                        label={this.labels.HubLabelVisitStart}
                        value={this.props.visitorStartDate}
                        onChange={e => this.props.startDateTimeChanged(e)}
                        input=
                        {{
                            error: this.props.showDateTimeError,
                            helperText: this.props.showDateTimeError ? this.labels.funcAnnouncementStartError_Message : ''
                        }}
                    />
                    <IbssLuxonDateTimePicker
                        label={this.labels.HubLabelVisitEnd}
                        value={this.props.visitorEndDate}
                        onChange={e => this.props.endDateTimeChanged(e)}
                        input=
                        {{
                            error: this.props.showDateTimeError,
                            helperText: this.props.showDateTimeError ? this.labels.funcAnnouncementStartError_Message : ''
                        }}
                    />
                </Box>
            </div>
        );
    }
}

export default EditHostDetails;


interface IProps
{
    hostDetail: IHostDetail;
    onHostNameChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    visitorStartDate: DateTime;
    startDateTimeChanged: (event:DateTime) => void; 
    visitorEndDate: DateTime;
    endDateTimeChanged: (event:DateTime) => void; 
    showDateTimeError: boolean;
    onVisitorArrivalLocations: (event: SelectChangeEvent) => void;
    visitorArrivalLocationId: string;
    visitorArrivalLocations: IFilterOptions[];
}

interface IState{}
