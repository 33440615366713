import "./FloorPlan.scss";
import React, { Component, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { appContext } from "../../../AppContext";
import { IApiCache } from "../../../Providers.Api/ApiCache";
import SpaceCard, { Props as SpaceCardProps } from "../../Cards/SpaceCard/SpaceCard";
import { DateTime } from "luxon";
import { DateHelper } from "../../../Common/DateHelper";
import IbssDialog from "../../Dialogs/BaseDialog/IbssDialog";
import { Box, Card, Typography } from "@mui/material";
import IbssButtonRedo from "../../Buttons/Button/IbssButton";
import { LightWeightSpace } from "../../../Providers.Api/Spaces/SpaceRepository";
import LoadingOverlay from "../../Navigation/LoadingOverlay/LoadingOverlay";
import { IEnvironmentalZoneData } from "../../../Providers.Api/EnvironmentalZoneData/GetByZoneIdsEndpoint";
import { SpacesFilter } from "../../../Providers.Api/Spaces/SpaceRepository";
import { IbssComponent } from "../../Core/BaseComponent/IbssComponent";
import Guid from "../../../Common/Guid";
import { IFloor, PagedResponse } from "../../../Providers.Api/Models";
import { Booking, IBooking, IRawBooking } from "./DataModels";
import IbssInputDropDown from "../../Inputs/SelectList/IbssInputDropDown";
import Helper from "../../../Common/Helper";

export default class FloorPlan extends IbssComponent<IProps, State>
{
    private labels = appContext().labels;
    private apiCache = appContext().apiCache;
    private apiClient = appContext().apiClient;
    private local = appContext().localStorageProvider;
    private panAmount = 200;
    private transformRef: React.RefObject<ReactZoomPanPinchRef>;
    private svgContainerRef: React.RefObject<HTMLDivElement>;
    private canSearchPeople: boolean;
    private spaces = new Map<string, LightWeightSpace>();
    private localStoarge = appContext().localStorageProvider;


    constructor(props: IProps)
    {
        super(props);
        this.state = new State();
        this.transformRef = React.createRef();
        this.svgContainerRef = React.createRef();
        this.canSearchPeople = this.local.hasRight('FLEX.Search.People');
    }

    public static emptySvg(): SVGElement
    {
        return FloorPlan.createSvg("<svg />");
    }

    public static createSvg(html: string): SVGElement
    {
        const container = document.createElement("div");
        container.innerHTML = html;
        const elementOrNull = Array.from(container.childNodes).filter(i => i.nodeName == "svg")[0] as SVGElement | null;
        const element = (elementOrNull ?? FloorPlan.emptySvg());
        return element;
    }

    public async componentDidMount(): Promise<void>
    {
        await this.loadSvg();
    }

    public async componentDidUpdate(oldProps: IProps): Promise<void>
    {
        if (oldProps.floorId != this.props.floorId)
        {
            await this.loadSvg();
        }
        else if (this.props.loadSpaces != Guid.empty && oldProps.loadSpaces !== this.props.loadSpaces)
        {
            await this.loadHighlightedSpaces();
        }
    }

    private async loadSvg(): Promise<void>
    {
        const nodeData = await this.localStoarge.getNodeData();

        const floor = nodeData.Regions
            .flatMap(region => region.Buildings.flatMap(building => building.Floors.map(floor => ({ building, floor }))))
            .find(({ floor }) => floor.Node_Id === this.props.floorId);

        const buildingId = floor?.building?.Node_Id;

        if (!buildingId)
        {
            this.spaces = new Map<string, LightWeightSpace>();
            this.setState({ svg: FloorPlan.emptySvg(), svgPreHighlightedSpaces: FloorPlan.emptySvg() });
            this.props.mapFailedToLoad();
            return;
        }

        const cachedSpaces = await this.apiCache.getSpacesByBuilding(buildingId);

        this.spaces = new Map<string, LightWeightSpace>(cachedSpaces.map(space => [
            space.Space_Id,
            {
                Space_Id: space.Space_Id,
                Space_Capacity: space.Space_Capacity,
                Space_Occupancy: space.Space_Occupancy,
                Meta_Bookable: space.Meta_Bookable
            } as LightWeightSpace
        ]));

        let svgString: string;
        try
        {
            const mapUrl = this.props.mapUrls.find(i => i.floorId == this.props.floorId) as IMapUrl;
            const truncatedUrl = mapUrl.url.replace(/^.*?\/images\//, "/");
            svgString = (truncatedUrl ? await this.apiCache.getFile(truncatedUrl) : "");
        }
        catch
        {
            this.setState({ svg: FloorPlan.emptySvg(), svgPreHighlightedSpaces: FloorPlan.emptySvg() });
            this.props.mapFailedToLoad();
            return;
        }

        await this.initialiseFloorOptions();
        await this.setStateAsync({ svg: FloorPlan.createSvg(svgString), svgPreHighlightedSpaces: FloorPlan.createSvg(svgString) });
        await this.showZoneData();
        await this.removeLargelabels();
        await this.flagAllSpaces();
        await this.showOccupacy();
        await this.setStateAsync({ svgPreHighlightedSpaces: this.state.svg.cloneNode(true) as SVGElement });
        await this.loadHighlightedSpaces();
    }

    private async initialiseFloorOptions(): Promise<void>
    {
        const buildingId = Helper.getBuildingNodeIdUsingFloorNodeId(this.props.floorId)
        const floors = Helper.getFloorsByBuildingId(parseInt(buildingId));

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1)); // sort by name

        await this.setStateAsync({ floorTypeOptions: options, });
    }

    private async loadHighlightedSpaces(): Promise<void>
    {
        try
        {
            const highlightedSpaces = new Array<IFloorPlanSpace>();
            await this.setStateAsync({ svg: this.state.svgPreHighlightedSpaces.cloneNode(true) as SVGElement, loading: true });

            let skipToken = "";
            do
            {
                if (this.props.onRequestSpaces)
                {
                    const pageOfSpaces = await this.props.onRequestSpaces(skipToken, this.props.floorId);
                    highlightedSpaces.push(...pageOfSpaces.spaces);
                    await this.highlightSpaces(pageOfSpaces.spaces);
                    await this.colourMap(pageOfSpaces.spaces);
                    skipToken = pageOfSpaces.skipToken;
                }
                if (this.props.spacesToHighlight)
                {
                    const highlightedSpaces = this.props.spacesToHighlight.map(x => ({colour: "", getColourFromData: true, id: x, periodCurrentSpaceValue: 0}))
                    const spaces = highlightedSpaces
                    await this.highlightSpaces(spaces);
                    await this.colourMap(spaces);
                }
            }
            while (skipToken);
            await this.setStateAsync({ highlightedSpaces: highlightedSpaces });
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    private getIaqStatus(iaq: number): string 
    {
        switch (iaq)
        {
            case 1:
                return this.labels.HubLabelGood;
            case 2:
                return this.labels.HubLabelOk;
            case 3:
                return this.labels.HubLabelPoor;
            default:
                return "";
        }
    }

    private getSoundLevelRange(soundRange: number): string 
    {
        switch (soundRange) 
        {
            case -1:
                return this.labels.HubLabelSilent;
            case 0:
                return this.labels.HubLabelQuiet;
            case 1:
                return this.labels.HubLabelNormal;
            default:
                return "";
        }
    }

    private async showZoneData(): Promise<void>
    {
        const svgNode = this.state.svg;
        try
        {
            const zoneIds = Array.from(svgNode.querySelectorAll('g[id="Environmental"]'))
                .filter(i => i instanceof SVGGElement)
                .map(i => i as SVGGElement)
                .flatMap(i => Array.from(i.querySelectorAll("g")))
                .map(i => i.id)
                .filter((str) => /^\d+$/.test(str));

            let zoneDatas: IEnvironmentalZoneData[];
            try
            {
                const cacheKey = JSON.stringify({ url: '/v1/:floorId/EnvironmentalZoneData/ZonesData', floorId: this.props.floorId, zoneIds: zoneIds });
                zoneDatas = await appContext().inMemoryCache.lazyGetWithQuickExpiry(cacheKey, () => this.apiClient.environemntalZoneData.getByZoneIds(this.props.floorId, zoneIds));
            }
            catch
            {
                return;
            }

            for (const zoneData of zoneDatas)
            {
                const { Env_Zone_Id: zoneId, Env_Temperature: temperature, IAQ_Status: iaqStatus, Env_Sound_Level_Range: soundLevelRange } = zoneData;
                const gElement = svgNode.querySelector(`g[data-env="${zoneId}"]`);

                if (!gElement)
                {
                    continue;
                }

                const textElements = gElement.querySelectorAll('text');
                for (const textElement of Array.from(textElements))
                {
                    const tspanElement = textElement.querySelector('tspan');
                    if (!tspanElement)
                    {
                        continue;
                    }

                    const dataText = textElement.getAttribute('data-text');
                    switch (dataText)
                    {
                        case 'Env_Temperature_Text':
                            tspanElement.textContent = `${temperature}°C`;
                            break;
                        case 'IAQ_Status_Text':
                            tspanElement.textContent = this.getIaqStatus(iaqStatus);
                            break;
                        case 'Env_Sound_Level_Range_Text':
                            tspanElement.textContent = this.getSoundLevelRange(soundLevelRange);
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        finally
        {
            await this.setStateAsync({ svg: svgNode });
        }
    }

    private async highlightSpaces(spacesToHighlight: IFloorPlanSpace[]): Promise<void>
    {
        const self = this;
        const svgNode = this.state.svg;
        try
        {
            for (let i = 0; i < spacesToHighlight.length; i++)
            {
                const space = spacesToHighlight[i];
                const spaceNode = document.evaluate(`.//*[@id='${space.id}']`, svgNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

                if (spaceNode instanceof SVGElement)
                {
                    const colouredSpaceNodes = document.evaluate(`../*[@id = '${space.id}' and ( @data-fillcolour = 'ui-secondary' or @data-strokecolour = 'ui-secondary' )] | .//*[@data-fillcolour = 'ui-secondary' or @data-strokecolour = 'ui-secondary']`, spaceNode, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
                    for (let j = 0; j < colouredSpaceNodes.snapshotLength; j++)
                    {
                        const colouredSpaceNode = colouredSpaceNodes.snapshotItem(j);
                        if (colouredSpaceNode instanceof SVGElement)
                        {
                            if (space.getColourFromData)
                            {
                                if (colouredSpaceNode.hasAttribute("data-fillcolour"))
                                {
                                    colouredSpaceNode.setAttribute("data-fillcolour", "ui-success");
                                }
                                if (colouredSpaceNode.hasAttribute("data-strokecolour"))
                                {
                                    colouredSpaceNode.setAttribute("data-strokecolour", "ui-success");
                                }
                            }
                            else
                            {
                                colouredSpaceNode.removeAttribute("data-fillcolour");
                                colouredSpaceNode.removeAttribute("data-strokecolour");
                                colouredSpaceNode.setAttribute("fill", space.colour);
                                const titleElement = document.createElement('title');
                                titleElement.textContent = `${this.labels.HubLabelUtilisation}: ${space ? Math.round(space.periodCurrentSpaceValue).toString() : 'null'}%`;
                                colouredSpaceNode.appendChild(titleElement);
                                
                                const textNode = spaceNode.querySelector("text");
                                if (textNode) 
                                {
                                    const tspanNodes = textNode.querySelectorAll("tspan");
                                    tspanNodes.forEach((tspanNode) => 
                                    {
                                        const tspanTitleElement = document.createElement('title');
                                        tspanTitleElement.textContent = `${this.labels.HubLabelUtilisation}: ${space ? Math.round(space.periodCurrentSpaceValue).toString() : 'null'}%`;
                                        tspanNode.appendChild(tspanTitleElement);
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
        finally
        {
            await this.setStateAsync({ svg: svgNode });
        }
    }

    private async colourMap(spacesToHighlight: IFloorPlanSpace[]): Promise<void>
    {
        const svgNode = this.state.svg;
        try
        {
            const colouredNodes = document.evaluate(`.//*[@data-fillcolour|@data-strokecolour]`, svgNode, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
            for (let i = 0; i < colouredNodes.snapshotLength; i++)
            {
                const colouredNode = colouredNodes.snapshotItem(i);
                if (colouredNode instanceof SVGElement)
                {
                    const fillColour = colouredNode.getAttribute("data-fillcolour");
                    const isBackground = (fillColour == "ui-background" && colouredNode.parentNode == svgNode || colouredNode.parentNode?.parentNode == svgNode);

                    if (isBackground)
                    {
                        colouredNode.setAttribute("fill", `var(--ui-background-alternate)`);
                    }
                    else if (fillColour)
                    {
                        colouredNode.setAttribute("fill", `var(--${fillColour})`);
                    }

                    const strokeColour = colouredNode.getAttribute("data-strokecolour");
                    if (strokeColour)
                    {
                        colouredNode.setAttribute("stroke", `var(--${strokeColour})`);
                    }
                    else
                    {
                        colouredNode.removeAttribute("stroke");
                    }
                }
            }
        }
        finally
        {
            await this.setStateAsync({ svg: svgNode });
        }
    }

    private async removeLargelabels(): Promise<void>
    {
        const svgNode = this.state.svg;
        try
        {
            if (svgNode == null)
            {
                return;
            }

            const largeLabelsNode = document.evaluate(`.//*[@id="Labels_Large" and @data-layer="Labels"]`, svgNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
            if (largeLabelsNode instanceof SVGElement)
            {
                largeLabelsNode.remove();
            }
        }
        finally
        {
            await this.setStateAsync({ svg: svgNode });
        }
    }

    private async flagAllSpaces(): Promise<void>
    {
        const svgNode = this.state.svg;
        try
        {
            const nodes = document.evaluate(`.//*[@id]`, svgNode, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
            for (let i = 0; i < nodes.snapshotLength; i++)
            {
                const node = nodes.snapshotItem(i);
                if (node instanceof SVGElement)
                {
                    const space = this.spaces.get(node.id);
                    if (space != null && space.Meta_Bookable != 0 && space.Meta_Bookable != 2)
                    {
                        node.setAttribute("data-isspace", "true");
                    }
                }
            }
        }
        finally
        {
            await this.setStateAsync({ svg: svgNode });
        }
    }

    private async showOccupacy(): Promise<void>
    {
        const svgNode = this.state.svg;
        try
        {
            const spaceElements = Array.from(svgNode.querySelectorAll('g[id="Complex"]'))
                .filter(i => i instanceof SVGGElement)
                .map(i => i as SVGGElement)
                .flatMap(i => Array.from(i.querySelectorAll("g")));

            for (const spaceElement of spaceElements)
            {
                const space = this.spaces.get(spaceElement.id);
                if (space == null)
                {
                    continue;
                }

                const { Space_Capacity, Space_Occupancy } = space;

                const numOfFreeSpacesElement = spaceElement.querySelector('#ItemValue tspan');
                if (numOfFreeSpacesElement)
                {
                    const numOfFreeSpaces = (Space_Capacity - Space_Occupancy);
                    numOfFreeSpacesElement.textContent = numOfFreeSpaces.toString();
                }

                const occupancyElement = spaceElement.querySelector('#Progress');
                if (occupancyElement)
                {
                    const maxOccupancyElement = spaceElement.querySelector('#ProgressBG');
                    const widthOfMaxOccupancy = Number(maxOccupancyElement?.getAttribute('width'));
                    const widthOfOccupancy = (Space_Occupancy / Space_Capacity) * widthOfMaxOccupancy;
                    occupancyElement.setAttribute('width', widthOfOccupancy.toString());

                    const percentOccupancy = (Space_Occupancy / Space_Capacity * 100);
                    const fillColour = (percentOccupancy < 50 ? "var(--ui-success)" : (percentOccupancy <= 75 ? "var(--ui-warn)" : "var(--ui-error)"));
                    occupancyElement.setAttribute('fill', fillColour);
                }
            }
        }
        finally
        {
            await this.setStateAsync({ svg: svgNode });
        }
    }

    private pan(setTransform: (newPositionX: number, newPositionY: number, newScale: number) => void, deltaX: number, deltaY: number): void
    {
        const transformState = this.transformRef.current?.state;
        if (transformState == null)
        {
            return;
        }
        setTransform(transformState.positionX + deltaX, transformState.positionY + deltaY, transformState.scale);
    }

    private async svgClicked(event: React.MouseEvent<HTMLDivElement, MouseEvent>): Promise<void>
    {
        const spaceElement = this.getSpaceElement(event.target as HTMLElement);

        if (spaceElement == null)
        {
            return;
        }
        if (this.props.enableSpaceClick)
        {
            const spaceId = spaceElement.id;
            await this.spaceClicked(spaceId);
        }
    }

    private getSpaceElement(element: HTMLElement): (HTMLElement | null)
    {
        if (element == this.svgContainerRef.current || element.parentElement == null)
        {
            return null;
        }
        else if (element.getAttribute("data-isspace") == "true")
        {
            return element;
        }
        else
        {
            return this.getSpaceElement(element.parentElement);
        }
    }

    private async spaceClicked(spaceId: string): Promise<void>
    {
        this.setState({ bookings: null });
        const isSpaceAvailable = (this.state.highlightedSpaces.find(i => i.id == spaceId) != null);
        if (!isSpaceAvailable && !this.canSearchPeople)
        {
            return;
        }

        const space = await appContext().apiCache.getSpace(this.props.floorId, spaceId);
        if (space == null)
        {
            return;
        }

        const spaceCardProps = SpaceCardProps.fromSpace(space);
        if (!isSpaceAvailable)
        {
            const response = await appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.bookingsOnThisDay.get<IRawBooking[]>({
                id: spaceId,
                nodeId: this.props.floorId,
            });
            this.setState({
                bookings: response?.map(x => (
                    {
                        ...x,
                        Booking_Start: DateHelper.fromIsoByNode(x.Booking_Start, this.props.floorId),
                        Booking_End: DateHelper.fromIsoByNode(x.Booking_End, this.props.floorId),
                        Booking_Share_Loc: x.Booking_Share_Loc == 1 ? true : false,
                        Booking_IsActive: x.Booking_IsActive == 1 ? true : false
                    }
                ))
    });
        }
        this.setState({ spaceCard: spaceCardProps, showBookButton: isSpaceAvailable });
    }

    private spaceModalClicked(): void
    {
        if (this.props.spaceModalClicked == null)
        {
            return;
        }
        this.props.spaceModalClicked(this.state.spaceCard?.spaceId ?? "");
        this.setState({ spaceCard: null });
    }

    private hideSpaceModalClicked(): void
    {
        this.setState({ spaceCard: null });
    }

    private async buildingDropdownChange(floorId: number): Promise<void>
    {
        await this.props.onFloorSelected?.(floorId);
    }

    public render(): JSX.Element
    {
        return (
            <TransformWrapper ref={this.transformRef}>
                {({ zoomIn, zoomOut, setTransform, resetTransform, ...rest }) => (
                    <>
                        <div className="floorplan">
                            <div className="floorplan-buttons">
                                <div className="floorplan-pan"></div>

                                <i className="floorplan-north fa fa-caret-up" onClick={() => this.pan(setTransform, 0, this.panAmount)} />
                                <i className="floorplan-north-east" onClick={() => this.pan(setTransform, -this.panAmount, this.panAmount)} />
                                <i className="floorplan-east fa fa-caret-right" onClick={() => this.pan(setTransform, -this.panAmount, 0)} />
                                <i className="floorplan-south-east" onClick={() => this.pan(setTransform, -this.panAmount, -this.panAmount)} />
                                <i className="floorplan-south fa fa-caret-down" onClick={() => this.pan(setTransform, 0, -this.panAmount)} />
                                <i className="floorplan-south-west" onClick={() => this.pan(setTransform, this.panAmount, this.panAmount)} />
                                <i className="floorplan-west fa fa-caret-left" onClick={() => this.pan(setTransform, this.panAmount, 0)} />
                                <i className="floorplan-north-west" onClick={() => this.pan(setTransform, this.panAmount, -this.panAmount)} />

                                <i className="floorplan-centre fa fa-bullseye" onClick={() => resetTransform()} />
                                <i className="floorplan-zoom-in fa fa-plus" onClick={() => zoomIn()} />
                                <i className="floorplan-zoom-out fa fa-minus" onClick={() => zoomOut()} />
                            </div>

                            {window.location.href.includes('space-analytics-heatmaps') ?
                                (
                                    <div style={{ color: `var(--ui-text)`, position: 'absolute', left: 20, top: 100, zIndex: 9, }}>
                                        <div>100%</div>
                                        <img src="/images/Rectangle 939.svg" alt="Gradient color" />
                                        <div>0%</div>
                                    </div>
                                ) : ('')
                            }

                            <TransformComponent>
                                {this.state.loading && <LoadingOverlay />}
                                <div className="floorplan-container" ref={this.svgContainerRef} onClick={e => this.svgClicked(e)} dangerouslySetInnerHTML={{ __html: this.state.svg.outerHTML }} />
                            </TransformComponent>
                            
                            {
                                this.props.displayFloorDropdown && !this.state.loading &&
                                <Card style={{ display: 'flex', position: 'absolute', bottom: '10px', width: '90%', marginLeft: '5%', padding: '5px', paddingTop: '15px' }} >
                                    <div style={{ marginLeft: '90px', marginRight: '250px' }}>
                                        <div style={{ fontSize: '16px' }}>{this.labels.HubLabelBuilding}</div>
                                        <Typography variant="h5">{Helper.getBuildingNameUsingFloorNodeId(this.props.floorId)}</Typography>
                                    </div>
                                    <div style={{ width: '300px' }} >
                                        <IbssInputDropDown id='floorsSelector' inputLabel={this.labels.HublabelFloor} options={this.state.floorTypeOptions} value={this.props.floorId} onChange={e => this.buildingDropdownChange(e.target.value)}/>
                                    </div>
                                </Card>
                            }

                            {
                                this.state.spaceCard &&
                                <IbssDialog
                                    id="map-modal"
                                    customClass="floor-dialog"
                                    open={this.state.spaceCard !== null}
                                    dialogContent=
                                    {
                                        <>
                                            <SpaceCard {...this.state.spaceCard} className="floor-space-card" closeIcon={true} pointer={false} handleClose={() => this.hideSpaceModalClicked()} bookings={this.state.bookings} />
                                            {this.state.showBookButton ?
                                                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                                                    {this.state.showBookButton && (<IbssButtonRedo color="primary" variant="contained" onClick={() => this.spaceModalClicked()}>{this.labels.HubButtonBookSpace}</IbssButtonRedo>)}
                                                </Box> : " "}
                                        </>
                                    }
                                    onClose={() => this.hideSpaceModalClicked()}
                                    className={`floorplan-modal ${this.state.spaceCard.showNoBookingMessage ? 'floorplan-noBookingData' : 'floorplan-hasBookingData'}`}
                                />
                            }
                        </div>
                    </>
                )}
            </TransformWrapper>
        );
    }
}

export interface IProps
{
    loadSpaces: Guid;
    mapUrls: IMapUrl[];
    floorId: number,
    startTime?: DateTime,
    endTime?: DateTime
    onRequestSpaces?: (skipToken: string, floorId: number) => Promise<IPagedFloorPlanSpaces>;
    spaceModalClicked?: (spaceId: string) => void;
    mapFailedToLoad: () => void;
    displayFloorDropdown?: boolean;
    onFloorSelected?: (floorId: number) => void;
    spacesToHighlight?: string[];
    enableSpaceClick: boolean;
}

export interface IPagedFloorPlanSpaces
{
    skipToken: string;
    spaces: IFloorPlanSpace[];
}

export interface IFloorPlanSpace
{
    id: string;
    colour: string;
    getColourFromData: boolean;
    periodCurrentSpaceValue: number;
}

export interface IMapUrl
{
    floorId: number;
    url: string;
}

export class State
{
    public svg = FloorPlan.emptySvg();
    public svgPreHighlightedSpaces = FloorPlan.emptySvg();
    public spaceCard: (SpaceCardProps | null) = null;
    public showBookButton = false;
    public loading = false;
    public bookings: IBooking[] | null = [];
    public highlightedSpaces: IFloorPlanSpace[] = [];
    public floorTypeOptions: IDropdownOptions[] = [];
}

export interface IDropdownOptions
{
    label: string;
    value: number;
}
